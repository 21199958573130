import React, { useEffect, useContext, useRef, useState} from 'react';
import web3 from '../../connection/web3';

import Web3Context from '../../store/web3-context';
import ERC20 from '../../abis/erc20.json';
import {RangeStepInput} from 'react-range-step-input';
import Switch from "react-switch";
import CollectionContext from '../../store/collection-context';
import PetControl from '../Layout/PetControl'
import { DropdownButton,Dropdown  } from 'react-bootstrap';
const bloggerList = ["Finance Blogger","Food Blogger", "Game Blogger","Coaching Blogger","Music Blogger","Photo Blogger","Psychology Blogger","Fitness Blogger","Travel Blogger"];
const BigNumber = require("bignumber.js");
//BigNumber.config({ RANGE: 1e+9 });
const DNA_EARS_MASK = Math.pow(2,4);//
const DNA_TAIL_MASK = Math.pow(2,8);//
const DNA_BEAVER_MASK = Math.pow(2,12);//
const DNA_BOTTOM_MASK = Math.pow(2,20);//
const DNA_MIDDLE_MASK = Math.pow(2,24);//
const DNA_GLASSES_MASK = Math.pow(2,32);//
const DNA_TOP_MASK = BigNumber("1099511627776");
const DNA_TOP_MASK_2 = BigNumber("17592186044416")
const DNA_DRESS_MASK =BigNumber("281474976710656");
const DNA_ACC_MASK = BigNumber("72057594037927936");
const DNA_ACC_MASK_2 = BigNumber("1152921504606846976");
function encodeDNA(pet){
  let dna = BigNumber(0);
  dna = dna.plus(pet.type.multipliedBy(8));
  dna = dna.plus(pet.color.minus(1));
  dna = dna.plus(pet.ears.multipliedBy(DNA_EARS_MASK)); 
  dna = dna.plus(pet.tail.multipliedBy(DNA_TAIL_MASK));
  dna = dna.plus(pet.beaver.multipliedBy(DNA_BEAVER_MASK));
  dna = dna.plus(pet.bottom.multipliedBy(DNA_BOTTOM_MASK));
  dna = dna.plus(pet.middle.multipliedBy(DNA_MIDDLE_MASK));
  dna = dna.plus(pet.glasses.multipliedBy(DNA_GLASSES_MASK));
  dna = dna.plus(pet.topColor.multipliedBy(DNA_TOP_MASK ));
  dna = dna.plus(pet.top.multipliedBy(DNA_TOP_MASK_2));
  dna = dna.plus(pet.dress.multipliedBy(DNA_DRESS_MASK));
  dna = dna.plus(pet.blogType.multipliedBy(DNA_ACC_MASK));
  dna = dna.plus(pet.blogAcc.multipliedBy(DNA_ACC_MASK_2));
   return dna.toFixed();
}
function randomIntFromInterval(min, max) { // min and max included 
  return BigNumber(Math.floor(Math.random() * (max - min ) + min));
}
const CreatePet = () => {
  const web3Ctx = useContext(Web3Context);
  const [petType, setPetType] = useState(false);
  const [petColor, setPetColor] = useState(1);
  const [hairColor, setHairColor] = useState(1);
  const [bloggerType, setBloggerType] = useState(1);
  const [petTypeStr, setPetTypeStr] = useState('cat');
  const [states , setStates] = useState([1,1,0,1,1,0,0,0,0]);
  const [refCode, setRefCode] = useState(0);
  const collectionCtx = useContext(CollectionContext);

  const [transactionActive, setTransactionActive] = useState(false);
  const [selectedToken, setSelectedToken] = useState(0);
  const [ticketsPrice, setTicketsPrice] = useState(0);
  const [buyAction, setBuyAction] = useState(true);
  const [wrongAcc, setWrongAcc] = useState(false);
  const [wrongDNA, setWrongDNA] = useState(false);
  const [mintDone, setMintDone] = useState(false);
  const tokens = [];
  const [priceUpdated, setPriceUpdated] = useState(false);

  tokens.push(["CLO", "0x0000000000000000000000000000000000000001",820]);
  tokens.push(["SOY", "0x9FaE2529863bD691B4A7171bDfCf33C7ebB10a65",820]);
  tokens.push(["CLOE", "0x1eAa43544dAa399b87EEcFcC6Fa579D5ea4A6187",820]);
  tokens.push(["BUSDT","0xbf6c50889d3a620eb42C0F188b65aDe90De958c4",820]);
  tokens.push(["ccBTT","0xCc99C6635Fae4DAcF967a3fc2913ab9fa2b349C3",820]);
  tokens.push(["ETC","0xCCc766f97629a4E14b3af8C91EC54f0b5664A69F",820]);
  tokens.push(["ETH","0xcC208c32Cc6919af5d8026dAB7A3eC7A57CD1796",820]);
  tokens.push(["BNB","0xcCDe29903E621Ca12DF33BB0aD9D1ADD7261Ace9",820]);
  tokens.push(["NULS","0x0000000000000000000000000000000000000001",119]);

  const [ticketsRef,setTicketsRef] = useState(1);

   useEffect(() => {
     if(collectionCtx.petzContract && !priceUpdated){
      setPriceUpdated(true);
      let id = tokens.findIndex(element => element[2] == web3Ctx.networkId);
      handleChangeToken(id,ticketsRef);
    }
  }, );
  useEffect(() => {
    let ref = localStorage["ref"];
    if(ref && ref.length>0){
      ref = parseInt(ref);
      if(ref >0 ){
        setRefCode(ref);
      }
    }
  },[] );

  const generateRandom = async()=>{
    let pet={
      type: randomIntFromInterval(0,100).toNumber()>50?BigNumber(1):BigNumber(0),
      color:randomIntFromInterval(1,5),
      ears:randomIntFromInterval(1,5),
      tail:randomIntFromInterval(1,5),
      beaver:randomIntFromInterval(0,10),
      bottom:randomIntFromInterval(1,10),
      middle:randomIntFromInterval(1,10),
      glasses:randomIntFromInterval(0,13),
      topColor: randomIntFromInterval(1,8),
      top:randomIntFromInterval(1,13),
      dress:randomIntFromInterval(1,20),
      blogType:randomIntFromInterval(1,9),
      blogAcc:randomIntFromInterval(1,5)
    }
    let DNA = encodeDNA(pet);
    let used = await collectionCtx.petzContract.methods.usedDNA(DNA).call();
    if(used){
      return generateRandom();
    }
    return pet;
  }
    const randomHandler = async()=>{
          if(!web3){
      return;
    }
    if(!web3Ctx.account){
      console.log("connection");
      return connectWallet();
    }
    if(web3Ctx.networkId != 820 && web3Ctx.networkId != 119){
      return addNetwork();
    }
      let pet = await generateRandom();
      setPetType(pet.type==1);
      setPetTypeStr(pet.type==1?'dog':'cat');
      setPetColor(parseInt(pet.color));
      onChangeRow(parseInt(pet.ears),0);
      onChangeRow(parseInt(pet.tail),1);
      onChangeRow(parseInt(pet.beaver),6);
      onChangeRow(parseInt(pet.bottom),4);
      onChangeRow(parseInt(pet.middle),3);
      onChangeRow(parseInt(pet.glasses),7);
      setHairColor(parseInt(pet.topColor));
      onChangeRow(parseInt(pet.top),2);
      onChangeRow(parseInt(pet.dress),8);
      setBloggerType(parseInt(pet.blogType));
      onChangeRow(parseInt(pet.blogAcc),5);      
    }
  const handleChangeToken = (key,tickets) => {
        if(!web3){
      return;
    }
    if(!web3Ctx.account){
      console.log("connection");
      return connectWallet();
    }
    if(web3Ctx.networkId != 820 && web3Ctx.networkId != 119){
      return addNetwork();
    }
    setSelectedToken(key);
    const updatePrice = async() => {
      let price = await collectionCtx.petzContract.methods.getPriceInToken(tokens[key][1],parseInt(tickets) || 0).call()/10**18;
      if(tokens[key][1] !=="0x0000000000000000000000000000000000000001"){
        let contract = new web3.eth.Contract(ERC20.abi, tokens[key][1]);
        if(contract){
          let allowed = (await contract.methods.allowance(web3Ctx.account,collectionCtx.petzContract._address).call())/10**18;
          let tmpPrice = price;
          tmpPrice = parseFloat(tmpPrice) *1.1;
          //console.log("allow=",parseFloat(allowed),parseInt(ticketsRef.current.value),tmpPrice,parseInt(ticketsRef.current.value) * (tmpPrice),"allow");
          if(parseFloat(allowed) >= (parseInt(tickets) * (tmpPrice))){
            setBuyAction(true);
          }else{
            setBuyAction(false);
          }
        }
      }
      setTicketsPrice(price);
    }
    updatePrice();
  };
  const ticketsChange = (event) => {
        if(!web3){
      return;
    }
    if(!web3Ctx.account){
      console.log("connection");
      return connectWallet();
    }
    if(web3Ctx.networkId != 820 && web3Ctx.networkId != 119){
      return addNetwork();
    }
    setMintDone(false);
    let val = parseInt(event.target.value);
    if(val<0){
      return;
    }
    if(val == 0){
      val = 1;
    }

    if(val>31){
      //val = 31;
    }
    setTicketsRef(val);
    handleChangeToken(selectedToken,val);
  };

  const mintPet =async() =>{
      if(!web3){
      return;
    }
    if(!web3Ctx.account){
      console.log("connection");
      return connectWallet();
    }
    if(web3Ctx.networkId != 820 && web3Ctx.networkId != 119){
      return addNetwork();
    }
    if(web3Ctx.networkId != 119){
      alert("New BlogPetz available exclusively on NULS chain");
      return;
    }

    if(ticketsRef == 0 ){
      return;
    }
    setMintDone(false);
    let DNAs = [];
    if(ticketsRef>1){
       for(var i=0;i<ticketsRef;i++){
         DNAs = [encodeDNA(await  generateRandom()),...DNAs];
       }
    }else{
      if(states[5] == 0){
        setWrongAcc(true);
        return;
      }
      let pet={
        type: petType? BigNumber(1):BigNumber(0),
        color:BigNumber(petColor),
        ears:BigNumber(states[0]),
        tail:BigNumber(states[1]),
        beaver:BigNumber(states[6]),
        bottom:BigNumber(states[4]),
        middle:BigNumber(states[3]),
        glasses:BigNumber(states[7]),
        topColor: BigNumber(hairColor),
        top:BigNumber(states[2]),
        dress:BigNumber(states[8]),
        blogType:BigNumber(bloggerType),
        blogAcc:BigNumber(states[5])
      }
      DNAs = [encodeDNA(pet),...DNAs];

      let used = await collectionCtx.petzContract.methods.usedDNA(DNAs[0]).call();
      if(used){
        setWrongDNA(true);
        return;
      }
    }
    let price = await collectionCtx.petzContract.methods.getPriceInToken(tokens[selectedToken][1],ticketsRef || 0).call();
    console.log("prepare",tokens[selectedToken][1],price);
    if(tokens[selectedToken][1] == "0x0000000000000000000000000000000000000001"){
      price = BigNumber(price).dividedBy(100).multipliedBy(105).toFixed();
      console.log(price);
      collectionCtx.petzContract.methods.mintPet(DNAs,ticketsRef,refCode).send({ from: web3Ctx.account, value: price })
      .on('transactionHash', (hash) => {setTransactionActive(true);})
      .on('receipt', (hash) => {
        setBuyAction(true);
        setTransactionActive(false);
        setMintDone(true);
      })
      .on('error', (error) => {setTransactionActive(false); window.alert('Something went wrong when pushing to the blockchain'); });       
    }else{
      let contract = new web3.eth.Contract(ERC20.abi, tokens[selectedToken][1]);
      if(contract){
        let allowed = (await contract.methods.allowance(web3Ctx.account,collectionCtx.petzContract._address).call())/10**18;
        let tmpPrice = price/10**18;
        tmpPrice = parseFloat(tmpPrice);
       console.log(parseFloat(allowed),tmpPrice);
        if(parseFloat(allowed) >= tmpPrice){
          console.log(DNAs,tokens[selectedToken][1],ticketsRef,0);
          collectionCtx.petzContract.methods.mintPetWithToken(DNAs,tokens[selectedToken][1],ticketsRef,refCode).send({ from: web3Ctx.account})
          .on('transactionHash', (hash) => {setTransactionActive(true);})
          .on('receipt', (hash) => {
            setBuyAction(true);
            setTransactionActive(false);
            setMintDone(true);
          })
          .on('error', (error) => {setTransactionActive(false); window.alert('Something went wrong when pushing to the blockchain'); });   
        }else{
          console.log("r:",tmpPrice,BigNumber(price).multipliedBy(4).toFixed());
          contract.methods.approve(collectionCtx.petzContract._address,BigNumber(price).multipliedBy(2).toFixed()).send({ from: web3Ctx.account})
             .on('transactionHash', (hash) => {
               setTransactionActive(true);
               setMintDone(false);
               setBuyAction(false);
             })
             .on('receipt', (hash) => {
               setBuyAction(true);
               setTransactionActive(false);
               setMintDone(false);
             })
             .on('error', (error) => {
              setTransactionActive(false);
              setMintDone(false);
              window.alert('Something went wrong when pushing to the blockchain');
            });
          }
        }
      }     
  }
  const handleChangeType = nextChecked => {
    setWrongDNA(false);
    setMintDone(false);
    setPetType(nextChecked);
    setPetTypeStr(nextChecked?'dog':'cat');
  };
  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };
  const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };
  const onChangeRow = (newVal,index) => {
    if(index == 5){
      setWrongAcc(false);
      setWrongDNA(false);
      setMintDone(false);
    }
    let tmpArray = states;
    tmpArray[index] = newVal;
    setStates([...tmpArray]);
    };
  return(

    <div>
    
    {
    <React.Fragment>
    <div id="section">
      <div className="generator-container container">
        <img className="lottery-back" src="assets/petzBack.png"/>
        <img className="petIcon1" src="assets/petIcon1.png"/>
        <img className="petIcon2" src="assets/petIcon2.png"/>
        <img className="petIcon3" src="assets/petIcon3.png"/>
        <img className="petIcon4" src="assets/petIcon4.png"/>
        <img className="petIcon5" src="assets/petIcon5.gif"/>
        <div className="lottery-header">
        <img className="lottery-logo" src="assets/petzLogo.gif"/>   
        <div className="lottery-title">   
                    {web3 && web3Ctx && !web3Ctx.account &&
               "Connect your wallet"
            } 
            { (web3 && web3Ctx && web3Ctx.networkId && (web3Ctx.networkId != 820 && web3Ctx.networkId != 119) )&&
               "Switch to Callisto Network"
            }   
            { (!web3)  &&
               "You need a DApp compatible browser"
            }  
                { web3Ctx.account  && (web3Ctx.networkId == 820 || web3Ctx.networkId == 119)&& !transactionActive && !mintDone &&
                  "Choose style of your pet"
                }
                { web3Ctx.account  && (web3Ctx.networkId == 820 || web3Ctx.networkId == 119) && transactionActive && !mintDone  &&
                  "Minting...."
                }
                { web3Ctx.account  && (web3Ctx.networkId == 820 || web3Ctx.networkId == 119)&& !transactionActive && mintDone &&
                  "See your pet(s) in a market"
                }
              </div>
        </div>
     
        <div className="generator-wrapper col">
        { 
          <div className="col generator-inputs">
            <img style={{zIndex:`${ticketsRef>1?5:-1}`}} src="assets/petzLeft.png" className="generator-inputs-img"/>
            <PetControl min={0} max={1} label={petTypeStr[0].toUpperCase()+petTypeStr.slice(1)} onChange={handleChangeType} value={petType}  />
            <PetControl min={1} max={5} label={`Skin color ${petColor}`} onChange={(e) => {setMintDone(false);setWrongDNA(false); setPetColor(e)}} value={petColor}  />
            <PetControl min={1} max={6} label={`Ears ${states[0]}`} onChange={(e) => onChangeRow(e, 0)} value={states[0]}   />
            <PetControl min={1} max={5} label={`Tail ${states[1]}`} onChange={(e) => onChangeRow(e, 1)} value={states[1]}   />
            <PetControl min={0} max={13} label={`Hair ${states[2]}`} onChange={(e) => onChangeRow(e, 2)} value={states[2]}   />
            <PetControl min={1} max={8} label={`Hair color ${hairColor}`} onChange={(e) =>{ setMintDone(false);setWrongDNA(false);setHairColor(e)}} value={hairColor}   />
            <PetControl min={1} max={10} label={`Eyes ${states[3]}`} onChange={(e) => onChangeRow(e, 3)} value={states[3]}   />
          </div>}
          <div className={`col generator-card`}>
            <img className="petz-item" src="assets/petzItem.png"/>
            {ticketsRef>1 &&<img alt="" className="petz-over" src="/assets/petzRandom.png"  />}
            <div className="petz-card">
              <img className="generator-card-back" src={`assets/petz/${petTypeStr}/bg.png`}/>
              <img className="generator-card-body" src={`assets/petz/${petTypeStr}/body/${petColor}.png`}/>
              <img className="generator-card-ears" src={`assets/petz/${petTypeStr}/ears/${petColor}/${states[0]}.png`}/>
              <img className="generator-card-tail" src={`assets/petz/${petTypeStr}/tail/${petColor}/${states[1]}.png`}/>
              <img className="generator-card-top" src={`assets/petz/general/top/${hairColor}/${states[2]}.png`}/>
              <img className="generator-card-middle" src={`assets/petz/general/middle/${states[3]}.png`}/>
              <img className="generator-card-bottom" src={`assets/petz/${petTypeStr}/bottom/${states[4]}.png`}/>
              <img className="generator-card-glasses" src={`assets/petz/general/glasses/${states[7]}.png`}/>
              <img className="generator-card-dress" src={`assets/petz/general/dress/${states[8]}.png`}/>
              <img className="generator-card-acc" src={`assets/petz/general/acc/${bloggerType}/${states[5]}.png`}/>
              <img className="generator-card-beaver" src={`assets/petz/general/beaver/${states[6]}.png`}/>
             </div>
             <div className="petz-info btn-green" style={{ marginLeft:"auto",marginRight: "auto"}}><b>Card(s) price:</b> {selectedToken>4?ticketsPrice.toFixed(6):ticketsPrice.toFixed(2)} {tokens[selectedToken][0]}</div>   
             <button onClick={randomHandler} style={{width:'250px',height:"42px" ,fontFamily: 'Open Sans', fontSize:'16px',background: '#482BD9',textTransform:"none"}}  className="mobile item-btn group">Generate</button>
             <React.Fragment>   
            <div style={{marginTop:'20px',maxWidth:"100%" ,marginLeft:"auto",marginRight:"auto" }} className="lottery-row mobile">
              <input placeholder="Cards" onChange={ticketsChange} value={ticketsRef} type="number" className="petz-input"/>
              <span style={{lineHeight: "37px",verticalAlign: "middle",height: "37px",minHeight: "37px",minWidth:"70px"}}>Pets with</span>
              <Dropdown className="lottery-drop">
                <Dropdown.Toggle className="lottery-btn">{tokens[selectedToken][0]}</Dropdown.Toggle>
                <Dropdown.Menu>
                {
                  tokens.map((item,name) => {
                    return (<Dropdown.Item onClick={() => handleChangeToken(name,ticketsRef)} key={name}>{item[0]}</Dropdown.Item>)
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <button onClick={mintPet} disabled={transactionActive} className={`item-btn petz-buy  ${transactionActive? "btn-secondary" : (buyAction || selectedToken === 0)? "btn-green" :"btn-pink"}`}>{transactionActive ? "Wait..." : (buyAction || selectedToken === 0)? "Mint" :"Enable"}</button>
            </div>
          </React.Fragment>  
             <div className="lottery-row lottery-bubble">
                { ticketsRef ==1 && wrongAcc==false&& (!transactionActive || !buyAction) && !wrongDNA && !mintDone &&
                  <img alt="" src="/assets/petMsg1.png"/>
                }
                { ticketsRef >1 && wrongAcc==false && !transactionActive && !wrongDNA && !mintDone &&
                  <img alt="" src="/assets/petMsg2.png"/>
                }
                { isNaN(ticketsRef) && wrongAcc==false && !transactionActive && !wrongDNA  && !mintDone &&
                  <img alt="" src="/assets/petMsg5.png"/>
                }
                { wrongAcc && !transactionActive && !wrongDNA && !mintDone &&
                  <img alt="" src="/assets/petMsg4.png"/>
                }
                { wrongDNA && !transactionActive && !mintDone &&
                  <img alt="" src="/assets/petMsg3.png"/>
                }
                {transactionActive && !mintDone && buyAction &&
                  <img alt="" src="/assets/petMsg6.png"/>
                }
                {mintDone && !transactionActive &&
                  <img alt="" src="/assets/petMsg7.png"/>
                }
              </div>
          </div>
                    { 
          <div className="col generator-inputs-right">
            <img style={{zIndex:`${ticketsRef>1?5:-1}`}} src="assets/petzRight.png" className="generator-inputs-img"/>
            <PetControl min={0} max={13} label={`Glasses/Mask ${states[7]}`} onChange={(e) => onChangeRow(e, 7)} value={states[7]}   />
            <PetControl min={1} max={10} label={`Mouth ${states[4]}`} onChange={(e) => onChangeRow(e, 4)} value={states[4]}   />
            <PetControl min={0} max={10} label={`Beard ${states[6]}`} onChange={(e) => onChangeRow(e, 6)} value={states[6]}   />
            <PetControl min={0} max={20} label={`Dress ${states[8]}`} onChange={(e) => onChangeRow(e, 8)} value={states[8]}   />
            <PetControl min={1} max={9} label={`Type: ${bloggerList[bloggerType-1].split(" ")[0]}`} onChange={setBloggerType} value={bloggerType}   />
            <PetControl min={1} max={5} label={`${bloggerList[bloggerType-1]} ${states[5]}`} onChange={(e) => onChangeRow(e, 5)} value={states[5]}   />
            <button onClick={randomHandler} style={{width:'250px',height:"42px",marginLeft:'0px' ,fontFamily: 'Open Sans', fontSize:'16px',background: '#482BD9',textTransform:"none"}}  className="item-btn group">Random</button>
            <React.Fragment>   
            <div style={{marginTop:'50px',maxWidth:"100%" }} className="lottery-row desktop">
              <input placeholder="Cards" onChange={ticketsChange} value={ticketsRef} type="number" min="1" max="10000" className="petz-input"/>
              <span style={{lineHeight: "37px",verticalAlign: "middle",height: "37px",minHeight: "37px"}}>Pets with</span>
              <Dropdown className="lottery-drop">
                <Dropdown.Toggle className="lottery-btn">{tokens[selectedToken][0]}</Dropdown.Toggle>
                <Dropdown.Menu>
                {
                  tokens.map((item,name) => {
                    if(item[2] == web3Ctx.networkId)
                    return (<Dropdown.Item onClick={() => handleChangeToken(name,ticketsRef)} key={name}>{item[0]}</Dropdown.Item>)
                  })}
                </Dropdown.Menu>
              </Dropdown>
              <button onClick={mintPet} disabled={transactionActive || isNaN(ticketsRef)} className={`item-btn petz-buy  ${transactionActive? "btn-secondary" : (buyAction || selectedToken === 0)? "btn-green" :"btn-pink"}`}>{transactionActive ? "Wait..." : (buyAction || selectedToken === 0)? "Mint" :"Enable"}</button>
            </div>
          </React.Fragment>
          </div>
          }
      </div>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default CreatePet;

