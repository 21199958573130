import React, { useContext, useState } from 'react';
import web3 from '../../connection/web3';
import NFTCollection from './NFTCollection';
import CollectionContext from '../../store/collection-context';
import Web3Context from '../../store/web3-context';

import Switch from "react-switch";
//import CollectionProvider from '../../store/CollectionProvider';
const Market = () => {
  const [checkedMy, setCheckedMy] = useState(false);
  const [checkedType, setCheckedType] = useState(false);
  const [checkedSale, setCheckedSale] = useState(false);
  const collectionCtx = useContext(CollectionContext);
  const handleChangeMy = nextChecked => {
    setCheckedMy(nextChecked);
  };
  const handleChangeType = nextChecked => {
    setCheckedType(nextChecked);
  };
  const handleChangeSale = nextChecked => {
    setCheckedSale(nextChecked);
  };
  const web3Ctx = useContext(Web3Context);
 /* const switchNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.switchToCallisto(web3);
  };
    const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };*/
  //const col=[];
  return(
    <div>
    { !web3 &&
      <div id="section">
        <div className="container">
          <p className="p-3">You need a DApp compatible wallet to use market. You can install <a href="https://metamask.io/">Metamask</a></p>
        </div>
      </div>
    }
    { (web3Ctx && web3Ctx.networkId && web3Ctx.networkId !== 820 && web3Ctx.networkId !== 119 ) &&
      <div id="section">
        <div className="container">
          <p className="p-3">On Site market available on Callisto Network only. For other chains you can use <a target="_blank" href="https://opensea.io/collection/vipwarz">OpenSea</a></p>
        </div>
      </div>
    }

    { (web3Ctx.networkId === 820 || web3Ctx.networkId === 119 ) &&
    <React.Fragment>
        <section className="mb-5">
                <img alt="" className="market-back" src="assets/marketBack.png"/>
        <div className="container">
          <div className="quick-nav my-3">
          <div className="announce-label"><label>
              VIP
              <Switch onChange={handleChangeType} checked={checkedType} className="react-switch" onColor="#828282"
                onHandleColor="#1BD495" handleDiameter={25} uncheckedIcon={false} checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={45} />
              BlogPetz
            </label></div>
            <label className="check-label">
              My Cards
              <Switch onChange={handleChangeMy} checked={checkedMy} className="react-switch" onColor="#828282"
                onHandleColor="#1BD495" handleDiameter={25} uncheckedIcon={false} checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={45} />
            </label>
            <label className="check-label">
              <span >On Sale</span>
              <Switch onChange={handleChangeSale} checked={checkedSale} className="react-switch" onColor="#828282"
                onHandleColor="#1BD495" handleDiameter={25} uncheckedIcon={false} checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={45} />
            </label>
            </div>
        </div>
    </section>
    <div id="section">
        <div className="container">
            <div className="item-grid">
               
              <NFTCollection marketType={checkedType} web3Ctx={web3Ctx} collectionCtx={collectionCtx} filterMy={checkedMy} filterSale={checkedSale}/>
             
            </div>

            <p className="copyright-text  bottom-label">VIPWarz takes 5% commission for sales</p>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default Market;