import React, { Component, Fragment } from 'react';
import { formatPrice } from '../../helpers/utils';
const imageFormat = {
    width: 353,
    height: 500
};
  let fixes = new Array();
  fixes[39] =   "https://cards.warz.vip/ipfs/Qme2HxsBdPjFccNk2EuiAUVQGgKR3JDLzvsmmxDp89e64M";
  let posters = new Array();
  posters[21] = "https://cards.warz.vip/ipfs/QmYrYU7xrQAKWqDKWsHRaRazSD5JhN5QRqRe7kLh3rUZEv";
  posters[27] = "https://cards.warz.vip/ipfs/QmZh9pQrXXA5K5A92bYqYtuuRQV33b3XEqvoX5AkA839x4";
  posters[33] = "https://cards.warz.vip/ipfs/QmTnA4eRbTWt997U9gb9YbVrG8XXmDhJ46BuAkaXAYwBFU";
  posters[39] = "https://cards.warz.vip/ipfs/Qmebi5TRG4UKdChNNS2KQQtswFBbTaQxwTQVjvFTN51DN4";
  posters[48] = "https://cards.warz.vip/ipfs/QmZEByMFjFqdBpra2t2aQphU1q2zJEE5i1puJvzPgdf4Tb";
  posters[54] = "https://cards.warz.vip/ipfs/QmZGmwkUWQSFtV7ndMsPwkF7DnQNQGDPCyDPHg8796ApGx";
  posters[60] = "https://cards.warz.vip/ipfs/QmYmX2tzdRToY68S2JehzczNSRW4BQThDPSAXrSdyLCWUW";
  posters[66] = "https://cards.warz.vip/ipfs/QmafvsXwfuFdF6gNB3Yv7Btz4okyw6ndcrRBPP3MABzAg1";
  posters[77] = "http://cards.warz.vip/ipfs/QmceWpRw3QSdh2G9Ux2LwX8dqbLtDwrzk1o3Dxc8vdmUAB";
  posters[83] = "https://cards.warz.vip/ipfs/QmTdQ3pSRDrMwTagG84ahgTYS5S3HyEttXwvtm56wP3hgS";
  posters[94] = "https://cards.warz.vip/ipfs/QmadKZG4sCDy9RUj1gpohHLik3jdUxndchk9D5mAib6gKT";
  posters[100] = "https://cards.warz.vip/ipfs/QmRXhurYuJF73QT36dGhcMSKqKToMMp5oXXK4PXHXgd4bb";
  posters[106] = "https://cards.warz.vip/ipfs/QmUtxwZzkhRNucczwENhaMzMKm7V1qvTsBCUqX7RX24rSi";
  posters[107] = "https://cards.warz.vip/ipfs/Qmdv6NNCQ8kXNmYWYADje14C9c4gHBhAEgf7ZE8rQAfUQV";
  posters[108] = "https://cards.warz.vip/ipfs/QmcLj4qJYTyH54YqyESsx3t24UNLZAFhSefigqG4tdaGWP";  

class CardImage extends Component{
  async drawImage(obj,canvas,cardImg,tokenID, url,likes, dislikes, stress,_name,_rating) {
  try{
    //console.log("Draw:",_name);
    let ctx = canvas.current.getContext("2d");
    let image = new Image;
    image.crossOrigin="anonymous";
    image.src = url;
    const points = [198,227,256,285,314];
    let rating = parseInt(_rating)/20;
    let fullRating = Math.floor(rating);
    let halfRating = parseInt(rating*100)  - parseInt(fullRating*100);

    await new Promise(r => image.onload=r, image.src=url);
    ctx.drawImage(image,0,0,imageFormat.width,imageFormat.height);
    ctx.drawImage(cardImg,0,0,imageFormat.width,imageFormat.height);
    for(var i=0;i<fullRating;i++){
      ctx.drawImage(this.pawImg,points[i],459,22,27);
    }
    if(halfRating>0 && i<5){
      let clipWidth = (halfRating)/(100/22);
      let clipSourceWidth = (halfRating)/(100/52);
      //console.log("width",clipWidth,halfRating);
      ctx.drawImage(this.pawImg,0,0,clipSourceWidth,68,points[i],459,clipWidth,27);
    }
  ctx.fillStyle="#000000";
  ctx.font = "30px 'Luckiest Guy'";
  ctx.fillText(_name, 20, 40);//name
  ctx.font = "36px 'Luckiest Guy'";
  likes = likes/10;
  dislikes = dislikes/10;
  var lMil = false;
  if(likes >=1000){
    likes/=1000;
    lMil= true;
  }
  var dMil = false;
  if(dislikes >=1000){
    dislikes/=1000;
    dMil= true;
  }
  ctx.fillText(likes, 55, 428);//likes
  ctx.fillText(dislikes, 200, 428);//dislikes
  if(stress%10>0){
    ctx.font = "23px 'Luckiest Guy'";
    ctx.fillText(stress/10, 119, 482);//stress
  }
  else{
    ctx.fillText(stress/10, 125, 487);//stress
  }
  
  ctx.font = "20px 'Luckiest Guy'";
  //ctx.fillText("stress", 50, 480);
  if(!lMil){
    ctx.fillText("th", 125, 424);
  }else{
    var x=0;
    if(likes>=10){x+=20;}
    if(likes>=100){x+=23;}
    if(parseInt(likes)<likes){x+=25;}
    ctx.fillText("m", 80+x, 424);
  }
  var x=0;
  if(dislikes>=10){x+=20;}
  if(dislikes>=100){x+=25;}
  if(parseInt(dislikes)<dislikes){x+=25;}
  if(!dMil){
    ctx.fillText("th", 225+x, 424);
  }else{

    ctx.fillText("m", 225+x, 424);
  }
  ctx.fillStyle="#ffffff";
  ctx.font = "24px 'Luckiest Guy'";
  //console.log("draw",obj.id);
  this.setState({poster: canvas.current.toDataURL()});;

  }catch(e){console.log(e);
  };
}
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.cardImg = new Image();
    this.cardImg.crossOrigin="anonymous"
    this.cardImg.src = "/assets/card/card.png";
    this.pawImg = new Image();
    this.pawImg.crossOrigin="anonymous"
    this.pawImg.src = "/assets/card/paw.png";
    let img;
    if(fixes[props.id] && this.props.vip ){
      img = fixes[props.id];
    }else{
     img = props.img;
    }
    if(img && img.length){
      img= img.replace("https://ipfs.infura.io/ipfs/","https://cards.warz.vip/ipfs/");
    }
      
    let poster =  (posters[props.id]  && props.vip) ? posters[props.id] : img;
    if(!poster){
      poster = "assets/card.png"
    }
    this.state  = {poster:poster,img:img};
   // console.log("constructor",this.props.id,poster,props.img);
    //this.setState({poster: poster});
  }
 componentDidMount(){
   let img;
    if(fixes[this.props.id] && this.props.vip ){
      img = fixes[this.props.id];
    }else{
      img = this.props.img;
    }
    if(img){
      img= img.replace("https://ipfs.infura.io/ipfs/","https://cards.warz.vip/ipfs/");
    }
    let poster = (posters[this.props.id]  && this.props.vip) ? posters[this.props.id] : img;
    if(!poster){
      poster = "assets/card.png"
    }
    this.setState({poster: poster,img:img});
   // console.log("did mount",this.props.id,this.state.poster,this.props.img);
    if(this.props.game && !this.props.game.legend){
      //console.log("legend",this.props.game.legend);
        this.drawImage(this,this.canvas,this.cardImg,this.props.id,poster,this.props.game.likes,this.props.game.dislikes,this.props.game.stress,this.props.game.name,this.props.game.rating);
      }
    //}
 }
 componentDidUpdate(prevProps, prevState) {

}
   shouldComponentUpdate(nextProps, nextState){
     if(this.state.img!=nextState.img){
       return true;
     }
    if (nextProps.img !== this.props.img  ||  (this.state.poster != nextState.poster && nextState.poster.length==0)) 
  {
    let img;
    if(fixes[nextProps.id] && nextProps.vip ){
      img = fixes[nextProps.id];
    }else{
      img = nextProps.img;
    }
    img= img.replace("https://ipfs.infura.io/ipfs/","https://cards.warz.vip/ipfs/");
      
    let poster = (posters[nextProps.id]  && nextProps.vip) ? posters[nextProps.id] : img;
    if(!poster){
      poster = "assets/card.png"
    }
    //console.log(nextProps.id,nextProps.img,this.props.img,poster,img);
    //console.log("shouldUpdate",this.props.id,poster,this.props.img,img);
    this.setState({poster: poster,img:img});
    if(nextProps.game && !nextProps.game.legend){
     this.drawImage(this,this.canvas,this.cardImg,nextProps.id,poster,nextProps.game.likes,nextProps.game.dislikes,nextProps.game.stress,nextProps.game.name,nextProps.game.rating);
    }
   return true;
  }

try{
  
  if(nextProps.game.name !== this.props.game.name ){
    if(nextProps.game && !nextProps.game.legend){
      this.drawImage(this,this.canvas,this.cardImg,nextProps.id,this.state.poster,nextProps.game.likes,nextProps.game.dislikes,nextProps.game.stress,nextProps.game.name,nextProps.game.rating);
    }
    return true;
  }
}catch(e){console.log(e);}

  if(nextProps.game !== this.props.game){
    if(nextProps.game && !nextProps.game.legend){
      this.drawImage(this,this.canvas,this.cardImg,nextProps.id,this.state.poster,nextProps.game.likes,nextProps.game.dislikes,nextProps.game.stress,nextProps.game.name,nextProps.game.rating);
    }
    return true;
  }


   if(this.props&& nextProps && this.props.selected!=nextProps.selected){

     return true;
   }
  if(this.props.id === nextProps.id && this.props.owner === nextProps.owner && this.props.img === nextProps.img ){
    if(this.props.offer === null && nextProps.offer === null && this.props.game === null && nextProps.game === null ) {
      return false;
    }
    if(this.props.offer !== null && nextProps.offer !== null && this.props.game === null){
      if(this.props.offer.id === nextProps.offer.id && this.props.offer.price === nextProps.offer.price && this.props.offer.user === nextProps.offer.user){
        return false;
      }
    }
  }
  return true;
}

  render() {

  
    return (
      <div className={this.props.static?"item2":"item"}>
          <canvas style={{display:"none"}} ref={this.canvas} width="353" height="500"/>
         <video muted={true} autoPlay={true} loop={true} className={`item-images main-img ${this.props.selected == true?"card-selection":""}`} src={this.state.img} poster={this.state.poster} onClick={(e) => {e.preventDefault();if(this.props.onClick){this.props.onClick({id:this.props.id,img:this.props.img,pet:!this.props.vip,offer:this.props.offer,game:this.props.game})}}} onDoubleClick={(e) => {e.preventDefault();if(this.props.onDoubleClick){this.props.onDoubleClick({id:this.props.id,img:this.props.img,pet:!this.props.vip,offer:this.props.offer,game:this.props.game})}}}/>                         
       </div>
    );

  }
}

export default CardImage;