import React, { useEffect, useContext, useRef, useState} from 'react';

const Team = () => {

  return(
    <div>
      <div style={{marginTop:"60px",marginBottom:"120px"}} id="section">
        <div className="container">
          <img className="market-back" style={{opacity:"0.5"}} src="/assets/team/back.png"/>
          <img className="desktop" src="/assets/team/desktop.png"/>
          <img className="mobile" src="/assets/team/mobile.png"/>
        </div>
      </div>
    </div>
  );
};

export default Team;

