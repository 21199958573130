import React, { useEffect, useContext, useRef, useState} from 'react';
import web3 from '../../connection/web3';

import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import Modal from 'react-modal';

const RegisterRef = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const [refCode,setRefCode] = useState(0);
  const [copied,setCopied] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
   useEffect(() => {
     if(collectionCtx.petzContract  && refCode == 0 && web3Ctx && web3Ctx.account){
       collectionCtx.petzContract.methods.referalsAddresses(web3Ctx.account).call().then(refCode=>{
         setRefCode(parseInt(refCode));
       });
     }
     
  },);

  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };
  const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };
  const registerReferral = async() => {
    if(!web3){
      return;
    }
    if(!web3Ctx.account){
      console.log("connection");
      return connectWallet();
    }
    if(web3Ctx.networkId != '0x334'){
      return addNetwork();
    }
    await collectionCtx.petzContract.methods.registerReferal().send({ from: web3Ctx.account})
    collectionCtx.petzContract.methods.referalsAddresses(web3Ctx.account).call().then(refCode=>{
      setRefCode(parseInt(refCode));
       });
    };
      const copyLink = () => {
        navigator.clipboard.writeText(`https://warz.vip/#/createpet?ref=${refCode}`);
        setCopied(true);
    };
  return(

    <div>

    { 
    <React.Fragment>
    <Modal className="rules-modal" overlayClassName="Overlay" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Rules">
    <img className="rules-close" src="assets/x.png" onClick={closeModal}/>
      <h4>Make money by selling BlogPetz.</h4>
      <ul>
        <li><img className="rules-img" src="assets/link.png"/>Tell the world about BlogPetz cards and use your referral link in your posts. Every referral that mints BlogPetz with your referral link will earn you 10% of price paid for the BlogPetz card minted</li>
        <li><img className="rules-img" src="assets/flash.png"/><span>BlogPetz are affordable playable characters with minimal stats for game. Players construct their design in a special section of the site <a href="https://warz.vip/#/createpet">https://warz.vip/#/createpet</a>. 
        You can choose the type of pet cat or dog,  color, hairstyle options, eyes, clothes and much more. You can also choose one of 9 blogger types: photo blogger, travel blogger, food blogger, and so on.</span></li>
        <li><img className="rules-img" src="assets/diamond.png"/>Each minted BlogPetz card unique. A total of 10,000 cards will be issued. The cost of cards increases after each new issue by 0.1%. So the first card cost 1000 CLO and the last will cost 10000 CLO.</li>
        <li><img className="rules-img" src="assets/like.png"/>In Q2 2022 we are launching our inventrory token(VWI) and 1 million tokens will be paid to BlogPetz holders, which gives about 40% APR.</li>
        <li><img className="rules-img-q" src="assets/question.png"/><span>More questions? Ask in our telegram <a href="https://t.me/VipWarz">https://t.me/VipWarz</a></span></li>
      </ul>
      
   </Modal>
    <div id="section">
      <div className="generator-container container">
        <img className="referral-back" src="assets/referralsBack.png"/>
        <div className="lottery-header">
        <img className="lottery-logo" src="assets/refLogo.gif"/>   
        <div className="lottery-title" style={{left:"90px"}}>   
            {web3 && web3Ctx && !web3Ctx.account &&
               "Connect your wallet"
            } 
            { (web3 && web3Ctx && web3Ctx.networkId && web3Ctx.networkId != '0x334' )&&
               "Switch to Callisto Network"
            }   
            { (!web3)  &&
               "You need a DApp compatible browser"
            }            
            { web3Ctx.account  && web3Ctx.networkId == '0x334' && refCode == 0 && !copied &&
               "Register Your Wallet to get your Ref Link"
            }
            { web3Ctx.account   && web3Ctx.networkId == '0x334' && refCode > 0 &&  !copied &&
               "Share you Referral Link on social media"
            }
            { web3Ctx.account  && web3Ctx.networkId == '0x334' && copied &&
              "Link copied to clipboard"
            }
              </div>
        </div>
     
        <div className="genrator-wrapper col">
          <div className={`col referal-card`}>
            <img style={{position:'absolute'}} src="assets/refItem.png"/>
            { refCode == 0 &&
              <button onClick={registerReferral} style={{height:"42px",fontFamily: 'Open Sans',background: '#1BD495',textTransform:"none",zIndex:"5",margin:"auto"}}  className="item-btn group ref-register">Register wallet</button>
            }
            { refCode> 0 &&
              <React.Fragment>
              <input className="desktop" style={{width:'65%',margin:"auto auto auto 50px",height:"42px",zIndex:"5",backgroundColor:"#EBF1F5",border:"1px solid #CFD6D9"}} type="text" readOnly={true} value={`https://warz.vip/#/createpet?ref=${refCode}`} />
              <button onClick={copyLink} style={{width:'100px',height:"42px",fontFamily: 'Open Sans', fontSize:'16px',background: '#482BD9',textTransform:"none",zIndex:"5",margin:"auto 80px auto 10px"}}  className="desktop item-btn group ref-copy" >Copy link</button>
              <input className="mobile" style={{width:'65%',margin:"auto auto auto 15px",height:"42px",zIndex:"5",backgroundColor:"#EBF1F5",border:"1px solid #CFD6D9"}} type="text" readOnly={true} value={`https://warz.vip/#/createpet?ref=${refCode}`} />
              <button onClick={copyLink} style={{width:'50px',height:"42px",fontFamily: 'Open Sans', fontSize:'16px',background: '#482BD9',textTransform:"none",zIndex:"5",margin:"auto 25px auto auto"}}  className="mobile item-btn group ref-copy" >Copy</button>

              </React.Fragment>
            }
          </div>
          <button style={{marginTop:'40px'}}  onClick={openModal} className="mobile btn-rules item-btn">F.A.Q.</button>
          <div style={{marginTop:'130px;'}} className="lottery-row referal-bubble">
            <img alt="" src="/assets/refMsg.png"/>
            <button onClick={openModal} className="desktop btn-rules item-btn">F.A.Q.</button>
         </div>
              
      </div>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default RegisterRef;

