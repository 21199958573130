import React, { useEffect, useContext, useRef, useState} from 'react';
import web3 from '../../connection/web3';

import Web3Context from '../../store/web3-context';
import ERC20 from '../../abis/erc20.json';
import {RangeStepInput} from 'react-range-step-input';
import Switch from "react-switch";
import CollectionContext from '../../store/collection-context';
import PetControl from '../Layout/PetControl'
import Modal from 'react-modal';
import Card from '../Layout/Card';
import CardImage from '../Layout/CardImage';
import { DropdownButton,Dropdown  } from 'react-bootstrap';
import {  useParams} from "react-router-dom";
import Posts from '../../data/posts.json';
let vvtAddress = [];
vvtAddress[820] = "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53";
vvtAddress[119] = "0x118dF330c1a2DAa667c5e8c7716cdc89C153a986";

const Game = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const [transactionActive, setTransactionActive] = useState(false);
  const actionLabels=["","Text","Photo","Video"];
  const [attack, setAttack] = useState(1);
  const [defence, setDefence] = useState(1);
  const { gameId } = useParams();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modal2IsOpen, setIsOpen2] = React.useState(false);
  const [rounds , setRounds] = React.useState([]);
  const [results , setResults] = React.useState([]);
  const [currentRound , setCurrentRound] = React.useState(null);
  const [roundActive , setRoundActive] = React.useState(false);
  const [time , setTime] = React.useState("");
  const [game , setGame] = React.useState({});
  const [didMove , setDidMove] = React.useState(-1);
  const [cardsInGame ,setCardsInGame] = React.useState([]);
  const [myCard ,setMyCard] = React.useState(null);
  const [opponentCard ,setOpponentCard] = React.useState(null);
  const [addedRound ,setAddedRound] = React.useState(-1);
  const [iAmPlayer ,setIAmPlayer] = React.useState(false);
  const [helperState, setHelperState] = useState(4);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [animationImage, setAnimationImage] = useState('');
  function openModal2() {
    setIsOpen2(true);
  }
  function closeModal2() {
    setIsOpen2(false);
  }
  function openModal() {
    setIsOpen2(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


    useEffect(async() => {    
      try {
         let index = collectionCtx.arenas.findIndex(game => game.id === parseInt(gameId));
        if(index!=-1){
          setGame(collectionCtx.arenas[index]);
        }
      } catch(error) {console.error(error);}
  }, [collectionCtx.arenas,web3Ctx.account]);


    useEffect(async() => {    
      if(web3 && web3Ctx && web3Ctx.account && game && game.status == 0 && game.owner!=web3Ctx.account && helperState!=0){
        setHelperState(0);
    }
  }, [web3,web3Ctx,game,game.status, game.owner, web3Ctx.account]);
  
  useEffect(async() => {    
      if(game && game.status ==1 && myCard && iAmPlayer && helperState!=1){
        setHelperState(1);
    }
  }, [game,game.status, myCard, iAmPlayer]);

    useEffect(async() => {    
      try {
          let _cardsInGame = [];
            let _cards = await  collectionCtx.gameContract.methods.cardsForGame(gameId).call();
            _cardsInGame = collectionCtx.collection.concat(collectionCtx.petzCollection).filter(NFT=>{
              return NFT.game && _cards.includes(NFT.game.cardId.toString());
            })
          setCardsInGame(_cardsInGame);
          let player1 = _cardsInGame.find(NFT=>{return NFT.game.owner == web3Ctx.account; });
          let player2 = null;
          if(player1){
            player2 = _cardsInGame.find(NFT=>{return NFT.game.owner != web3Ctx.account;})
            setIAmPlayer(true);
          }else{
            player2 = _cardsInGame[0];
            player1 = _cardsInGame[1];
            setIAmPlayer(false);
          }
          setMyCard(player1);
          setOpponentCard(player2);
      } catch(error) {console.error(error);}
  },[game,game.status,web3Ctx.account]);

  const calcTime = ()=>{
    setCurrentRound(currentRound=>{
    if(!currentRound){
      setRoundActive(false);
      return currentRound;
    }
    var distance = parseInt(currentRound.end) *1000 - Date.now();
    if(distance<0){
      setTime(`00:00`);
      setRoundActive(false);
      return currentRound;
    }
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if(hours == 0){
      hours = "";
    }else{
      hours = hours.toString();
      if(hours.length<2)
        {
          hours= "0".hours;
        };
        hours = hours + ":";
    }
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    minutes = minutes.toString();
    if(minutes.length<2)
      {
        minutes= "0" + minutes;
      };
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    seconds = seconds.toString();
    if(seconds.length<2)
      {
        seconds= "0" + seconds;
      };
    setTime(`${hours}${minutes}:${seconds}`);
    setRoundActive(true);
    return currentRound;
    })
  }
  const calcBank = ()=>{
    let fee = game.fee/10**18;
    let bank = fee * game.users;
    let labelBank = "";
    let sign = "";
    if(bank>1000){
      bank/=1000;
      sign=" th";
    }
    if(bank>1000){
      bank/=1000;
      sign=" m";
    }
    bank = Math.round(bank);
    return bank+" VVT";
  }

    const hideAnimation = ()=>{
setAnimationVisible(true);
      setTimeout(() => {
            setAnimationVisible(false);
            setAnimationImage("pixel");
      }, 900);
  }

  useEffect(() => {
  document.getElementsByClassName("game-main-area")[0].scrollTo(0, 0);
}, [rounds])
  const loadRounds = async()=>{
    if(!collectionCtx.gameContract || !game.id){
      return
    }
    let count = await collectionCtx.gameContract.methods.totalRoundsForGame(game.id).call();
    let _currentRound = await collectionCtx.gameContract.methods.rounds(game.id,count).call();
    setCurrentRound(_currentRound);
    let _rounds = [];
    for(var i=parseInt(count);i>=0;i--){

      let _data = await collectionCtx.gameContract.methods.roundResults(game.id,i).call();
      if(!_data || _data == undefined){
        continue;
      }
            console.log("round:",i,_data);
      let moves =[];
      for(var j=0;j<_data.length;j++){
        if(parseInt(_data[j].id) == 0 && parseInt(_data[j].target) == 0){
          continue;
        }
        console.log(_data[j]);
        moves.push({id:parseInt(_data[j].id),target:parseInt(_data[j].target),stress:parseInt(_data[j].stress),likes:parseInt(_data[j].likes),dislikes:parseInt(_data[j].dislikes),hype:parseInt(_data[j].hype),zone:parseInt(_data[j].zone),resultText:parseInt(_data[j].resultText),resultImage:parseInt(_data[j].resultImage)})
      }
      _rounds.push(moves);
    }
    setRounds([..._rounds]);
    if(game.status == 2){
      let _results = [];
      let _data = await collectionCtx.gameContract.methods.resultForGame(game.id).call();
      if(!_data || _data == undefined){
        console.log("no data:");
        return; 
      }
      for(var i=0;i<_data.length;i++){
              console.log(_data[i]);
        _results.push({id:parseInt(_data[i].id),xp:parseInt(_data[i].experience),payment:parseInt(_data[i].payment)/10**18,win:_data[i].win});
      }
      console.log(_results);
      setResults([..._results]);
      setTimeout(()=> setIsOpen(true),5000);
    }   
  }

  const loadRound = async(event)=>{
    setGame(game=>{
       if(parseInt(event.returnValues.gameId)!=game.id){
          return game;
        }
        setAddedRound(addedRound => {
        let id = parseInt(event.returnValues.gameId);
        let round = parseInt(event.returnValues.round);
        if(round<=addedRound){
          return addedRound;
        }
         addedRound= round;
         collectionCtx.gameContract.methods.rounds(id,round+1).call().then(_curRound=>{
           setCurrentRound(_curRound);
         })
        
        collectionCtx.gameContract.methods.roundResults(id,round).call().then(_data=>{
          setMyCard( _myCard =>{
            let moves =[];
            let myHype = 0;
            let opponentHype = 0;
            for(var j=0;j<_data.length;j++){
              if(parseInt(_data[j].id) == 0 && parseInt(_data[j].target) == 0){
                continue;
              }
              moves.push({id:parseInt(_data[j].id),target:parseInt(_data[j].target),stress:parseInt(_data[j].stress),likes:parseInt(_data[j].likes),dislikes:parseInt(_data[j].dislikes),hype:parseInt(_data[j].hype),zone:parseInt(_data[j].zone),resultText:parseInt(_data[j].resultText),resultImage:parseInt(_data[j].resultImage)})
              console.log("animation:",_myCard.game.cardId,parseInt(_data[j].id))
              if(parseInt(_data[j].id) == _myCard.game.cardId)
                {
                  myHype = parseInt(_data[j].hype)
                }else{
                  opponentHype = parseInt(_data[j].hype);
                }
            }
            let animation = "la";
            if(myHype>opponentHype){
              animation="wa";
            }

            animation+=Math.round((Math.random()*2)-1).toString();
            console.log("animation2:",animation,myHype);
            if(myHype == 0 && opponentHype ==0 ){}else{
              setAnimationImage(animation);
            }
            setRounds(oldRounds=>{
              return [moves,...oldRounds];
            });
            return _myCard;  
          });
        });
      return addedRound;
    })
      return game;
    })
  };
  const startGame = async(event)=>{
      setGame((game)=>{
          if(parseInt(event.returnValues.gameId) == parseInt(game.id)){
            collectionCtx.gameContract.methods.rounds(game.id,0).call().then(_currentRound=>{
              setCurrentRound(_currentRound);
            });  
          }
      return game;    
      })
  }
    const endGame = async(event)=>{
      setGame((game)=>{
          if(parseInt(event.returnValues.gameId) == parseInt(game.id)){
                  let _results = [];
            collectionCtx.gameContract.methods.resultForGame(game.id).call().then(_data=>{
      if(!_data || _data == undefined){
        console.log("no data:");
        return; 
      }
      for(var i=0;i<_data.length;i++){
              console.log(_data[i]);
        _results.push({id:parseInt(_data[i].id),xp:parseInt(_data[i].experience),payment:parseInt(_data[i].payment)/10**18,win:_data[i].win});
      }
      console.log(_results);
      setResults([..._results]);
      setTimeout(()=> setIsOpen(true),5000);
    });
    }
    return game;
      })
  }
  useEffect(() => {    
      try {
        loadRounds();
      } catch(error) {console.error(error);}
  }, [collectionCtx.gameContract,game]);
  useEffect(()=>{
    if(!collectionCtx.gameContract){
      return;
    }
    setInterval(calcTime,1000);
    console.log("subscribe");
    collectionCtx.gameContract.events.GameStart().on('data', (event) => {
        startGame(event);
    });
    collectionCtx.gameContract.events.GameEnd().on('data', (event) => {
      endGame(event);
    });
    collectionCtx.gameContract.events.RoundEnd().on('data', (event) => {
      loadRound(event);
    });
    },[collectionCtx.gameContract])
  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };
  const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };

  const joinHandler = async(activeGameCard) => {  
    let VVTcontract = new web3.eth.Contract(ERC20.abi, vvtAddress[web3Ctx.networkId]);//vvt
    let allowance = await VVTcontract.methods.allowance(web3Ctx.account,collectionCtx.gameContract.options.address).call();
    
    if(parseInt(allowance)>game.fee){
      collectionCtx.gameContract.methods.joinGame(parseInt(gameId),activeGameCard.game.cardId).send({ from: web3Ctx.account});
    }else{
      VVTcontract.methods.approve(collectionCtx.gameContract.options.address,web3.utils.toWei("1000000")).send({ from: web3Ctx.account }).on('transactionHash',()=>{ 
      }).on('receipt', async(hash) => {
        collectionCtx.gameContract.methods.joinGame(parseInt(gameId),activeGameCard.game.cardId).send({ from: web3Ctx.account});
      }).on('error', (error) => {
        window.alert('Something went wrong when pushing to the blockchain');
       })
    }
  };
  const cancelHandler = async() => {  
    collectionCtx.gameContract.methods.cancelGame(parseInt(gameId)).send({ from: web3Ctx.account });
  };
  const finshHandler = async() => {  
    collectionCtx.gameContract.methods.checkRound(parseInt(gameId)).send({ from: web3Ctx.account,gas:1000000 });
  };
  const doMove = async() => {  
    let round = game.currentRound;
    let resultText = Math.floor(Math.random() * Posts.length);
    await collectionCtx.gameContract.methods.makeMove(parseInt(gameId),myCard.game.cardId,attack,defence,opponentCard.game.cardId,resultText,1).send({ from: web3Ctx.account,gas:1000000 });
    setDidMove(round);
    setHelperState(Math.floor(Math.random() * (7) + 10))
  };

     let data= collectionCtx.collection.concat(collectionCtx.petzCollection).filter(NFT=>{
        const onSale = (NFT.offer != null && NFT.offer.id == NFT.id) ? true : false;
        const inGame = (NFT.game != null && NFT.game.inGame) ? true : false;
        const owner = !onSale ? (inGame ? NFT.game.owner : NFT.owner) : NFT.offer.user;
        if(web3Ctx.account != owner){
          return false;
        }
        return inGame && NFT.game.gameId == 0;
      }).sort((a, b) => a.id < b.id? 1 : -1)
  return(

    <div>
    
    {
    <React.Fragment>
    <div id="section" style={{position:"relative"}}>
    <img className="desktop arenaIcon1" src="assets/game/arena0/icon1.png"/>
    <img className="desktop arenaIcon2" src="assets/game/arena0/icon2.png"/>
    <img className="desktop arenaIcon3" src="assets/game/arena0/icon3.png"/>
    <div className="desktop arenaIcon4">
      <img src="assets/game/arena0/icon4.png"/>
      <img className="arenaIcon5" src="assets/game/arena0/logo.gif"/>
    </div>
    <Modal className="arena-modal" overlayClassName="Overlay-arena" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Results">
    <img className="rules-close" src="assets/x2.png" onClick={closeModal}/>
        <h1 style={{width:"fit-content",marginLeft:"auto",marginRight:"auto",color:"white", marginTop:"-70px"}}>GAME RESULTS</h1>
            <div className="row" style={{color: "white",gap:"20px",height:"100%"}}>
            { 
              results.sort((a, b) => !a.win ? 1 : -1).map((_res, _key) => {
              let NFT = cardsInGame.find(NFT=>{
                return NFT.game.cardId == parseInt(_res.id);
               })
               if(!NFT){return ""}
               let payment = _res.payment.toFixed(2);
              return(

                <div key={_key} className="res-wrapper">
                     <div style={{background: _res.win?"#C9AE62":"black",padding:"10px", border:"4px solid white",borderRadius:"15px",position:"relative" }}>
                     <div className="gameResImg"  >
                        <CardImage fontSize={"18px"} static={true} img={NFT.img} vip={NFT.vip} id={NFT.id} offer={NFT.offer} game={NFT.game}/>
                     </div>
                        {_res.win && <img className="resWin" src="assets/game/winLike.png" />}
                        <h6 style={{marginTop: "20px"}}>Player result</h6>
                        <h5 style={{fontFamily:"'Open Sans', sans-serif"}}>{_res.xp} XP</h5>
                        <h5  style={{fontFamily:"'Open Sans', sans-serif",marginBottom: "0px"}}>{payment} VVT</h5>
                        </div>
                </div>
                
              )})
            }
            </div>
   </Modal>
  <Modal className="arena-modal" overlayClassName="Overlay-arena" isOpen={modal2IsOpen} onRequestClose={closeModal2} contentLabel="Rules">
    <img className="rules-close" src="assets/x2.png" onClick={closeModal2}/>
            <div style={{overflowY: "scroll",height:"92%"}} className="row text-center">
            { 

              data.filter(NFT=>{
                return NFT.game.gameId == 0;
              }).map((NFT, _key) => {
              return(
                <Card onClick={nft=>{closeModal2();joinHandler(nft);}} hideButtons={true} vip={!NFT.pet} key={NFT.pet?(NFT.id+1000):NFT.id} game={NFT.game} id={NFT.id} offer={NFT.offer} img={NFT.img} owner={NFT.owner} web3Ctx={web3Ctx} buyHandler={()=>{}} makeOfferHandler={()=>{}} 
                cancelHandler={()=>{}} />
              )})
            }
            </div>
   </Modal>
      <div className="generator-container container">
        <div className="lottery-header">
        <img className="lottery-logo" src="assets/playLogo.gif"/>   
        <div className="lottery-title">   
          {web3 && web3Ctx && !web3Ctx.account &&
               "Connect your wallet"
            } 
            { (web3 && web3Ctx && web3Ctx.networkId && web3Ctx.networkId != 820 )&&
               "Switch to Callisto Network"
            }   
            { (!web3)  &&
               "You need a DApp compatible browser"
            } 
            { game.id && game.status == 0  &&
              `Game filling: ${game.users}/${game.maxUsers} users. Game bet ${game.fee/10**18} VVT`
            } 
            { game.id && game.status == 1  &&
              `Round ${game.currentRound+1} / ${time} / Bank: ${calcBank()}`
            } 
            { game.id && game.ended == 2  &&
              "Game ended"
            } 
              </div>
        </div>
     
        <div className="generator-wrapper col">
          <div className="row game-inputs">
            <div className="col" style={{width: "260px",padding:"0px"}}>
              <div >
               <h4 className="game-label">My Inventory</h4>
                <div className="game-inventory-area">
                <div className="profile-soon" style={{fontSize:"35px",lineHeight:"35px"}}>COMING<br/>SOON</div>
                </div>
              </div>
              <div style={{width:(game&&game.status==1)?"260px":"450px"}}>
                <h4 className="game-label"></h4>
                <div style={{width:(game&&game.status==1)?"100%":"100%",padding:"10px",paddingLeft:(game&&game.status==1) ?"10px":"30px",marginTop:"20px"}} className="game-team-area">
                { (game&&game.status==1) && <img src="/assets/game/arena0/textleft1.png" alt=""/>}
                { (!game || game.status!=1) && <img style={{width:"90%"}} src="/assets/game/arena0/textleft2.png" alt=""/>}
                </div>
                <div style={{left:(game&&game.status==1)?"40%":"80%"}} className="game-triangle">
                  <div className="game-triangle-inner"></div>
                </div>

              </div>          
            </div>
            <div style={{width: "200px",padding:"0px"}}>
              <h4 style={{paddingLeft:"20px"}} className="game-label">My Card</h4>
              {!myCard &&
                <img alt="" className="game-card-img" src="assets/card.png"/>
              }
              {myCard &&
                <div style={{paddingTop:"4px"}} className="game-card-img">
                    <img style={{display:animationVisible?"inline":"none"}} onLoad={hideAnimation} className="petztagramm-animation" src={`/assets/game/arena0/${animationImage}.gif`}/> 
                
                  <CardImage static={true} fontSize={"14px"} img={myCard.img} poster={myCard.poster} vip={myCard.vip} id={myCard.id} offer={myCard.offer} game={myCard.game}/>
                </div>
              }
              { game && game.status ==1 && myCard && iAmPlayer &&
                <div className="game-card-area">   
                <h4 style={{marginTop:"5px"}} className="game-label">Hype with </h4>
                <PetControl onMouseEnter={(e) =>{setHelperState(2)}} className="fullWidth" min={1} max={3} label={`${actionLabels[attack]}`} onChange={(e) => {setAttack(e);}}  value={attack}/>
                <h4 className="game-label">Send dislikes to</h4>
                <PetControl onMouseEnter={(e) =>{setHelperState(3);}} className="fullWidth" min={1} max={3} label={`${actionLabels[defence]}`} onChange={(e) => {setDefence(e)}} value={defence}   />
                <button  onClick={doMove} style={{display: "inline-flex",alignItems: "flex-end",marginTop:"0px",width:'100%',height:"42px",marginLeft:'0px' ,fontFamily: 'Open Sans', fontSize:'20px',background: (game.currentRound !=didMove && roundActive == true)?'#482BD9':'gray',textTransform:"none"}}  disabled={!(game.currentRound !=didMove && roundActive == true)} className={`item-btn ${(game.currentRound !=didMove && roundActive == true)?"btn-green":""} group`}>Post</button>
              </div>
            }
            </div>

          </div>
          <div style={{margin:"0px",padding:"0px",position:"relative"}}>
          <div className="game-main-area">  
          {
            rounds.map((item, item_key) => {
              return (item.map((round, _key)=>{
                let actionCard = collectionCtx.collection.concat(collectionCtx.petzCollection).find(nft => {return nft.game && nft.game.cardId == round.id;})
                let targetCard = collectionCtx.collection.concat(collectionCtx.petzCollection).find(nft => {return nft.game && nft.game.cardId == round.target;})
                let actionName = actionCard && actionCard.game && actionCard.game.name ?actionCard.game.name.toUpperCase():"";
                let targetName = targetCard && targetCard.game && targetCard.game.name ?targetCard.game.name.toUpperCase():"";
                let actionNum = actionCard ?actionCard.id:"";
                let targetNum = targetCard ?targetCard.id:"";
                let actionPet = actionCard && actionCard.game && actionCard.game.petType ?actionCard.game.petType:0;
                let targetPet = targetCard && targetCard.game && targetCard.game.petType ?targetCard.game.petType:0;
                let likes = (round.likes/10).toFixed(1) + " th";
                let dislikes = (round.dislikes/10).toFixed(1) + " th";
                let hype = (round.hype/10).toFixed(1) + " th";
                let stress = (round.stress/10).toFixed(1);
                return(
                  <React.Fragment key={item_key*1000+_key}>
                  <div className="game-post-results popout2">
                  <b>[{actionNum}] {actionName}</b> posted a {actionLabels[round.zone]} which get <b>{likes}</b> Likes.{round.dislikes>0?<React.Fragment><b>[{targetNum}] {targetName}</b> disliked this post with <b>{dislikes}</b> Dislikes.</React.Fragment>:""}<b>[{actionNum}] {actionName}</b> scored <b>{hype}</b> popularity and news said stress of <b>[{targetNum}] {targetName}</b> increased to <b>{stress}</b>
                  </div>
                  <div className="game-post popout" key={item_key*1000+_key}>
                    <img className="game-ava" src={`/assets/game/ava${actionPet}.png`}/>
                    <h4 className="game-label2">{actionName}</h4>
                    <img className="game-post-dots" src="assets/game/dots.png"/>
                    { (round.zone ==1 || round.zone == 2) && Posts[round.resultText] && <img className="game-post-img" src={`/assets/game/${round.zone}/${Posts[round.resultText].id}.png`}/>}
                    { (round.zone == 3)&& Posts[round.resultText] && <video muted={true} autoPlay={true} loop={true} style={{width:"100%"}} className="game-post-img" src={`/assets/game/${round.zone}/${Posts[round.resultText].id}.webm`}/>}
                    <div style={{position:"relative",width:"100%",padding:"5px"}}>
                      <img className="game-post-likes" src="/assets/game/like.png"/>
                      <h4 className="game-label-likes">{likes}</h4>
                      <img className="game-post-likes" src="/assets/game/dislike.png"/>
                      <h4 className="game-label-likes">{dislikes}</h4>
                      <img className="game-post-paw" src="assets/game/paw.png"/>
                      {Posts[round.resultText] && round.zone!=1 && <div className="game-post-topic">{Posts[round.resultText].text}</div>}
                     </div>
                  </div>
                  </React.Fragment>
                )
              }));
            })
          }
          </div>
          { web3 && web3Ctx && web3Ctx.account && game && game.status == 0 && game.owner!=web3Ctx.account &&
            <button onClick={openModal2}  style={{display: "inline-flex",alignItems: "flex-end",position:"absolute",bottom:"-15px",zIndex:"15",width:'80%',height:"42px",left:"10%" ,fontFamily: 'Open Sans', fontSize:'20px',background: '#482BD9',textTransform:"none"}}  className="item-btn btn-green group">Join</button>
          }
          { web3 && web3Ctx && web3Ctx.account && game && game.status == 0 && game.owner==web3Ctx.account &&
            <button onClick={cancelHandler}  style={{display: "inline-flex",alignItems: "flex-end",position:"absolute",bottom:"-15px",zIndex:"15",width:'80%',height:"42px",left:"10%" ,fontFamily: 'Open Sans', fontSize:'20px',background: '#482BD9',textTransform:"none"}}  className="item-btn btn-green group">Cancel game</button>
          }
          { web3 && web3Ctx && web3Ctx.account && game && game.status ==1 && roundActive == false && myCard && iAmPlayer &&
            <button onClick={finshHandler}  style={{display: "inline-flex",alignItems: "flex-end",position:"absolute",bottom:"-15px",zIndex:"15",width:'80%',height:"42px",left:"10%" ,fontFamily: 'Open Sans', fontSize:'20px',background: '#482BD9',textTransform:"none"}}  className="item-btn btn-green group">Finish Round</button>
          }
          <div style={{left:"5%", position:"absolute",bottom:"-150px"}} className="lottery-bubble">
              <img alt="" src={`/assets/helpers/game/play/${helperState}.png`}/>
            </div>
          </div>

          <div className="col game-inputs-right">
            <div className="row" style={{width: "100%",padding:"0px"}}>
              <div style={{width: "200px",padding:"0px"}}>
                <h4 className="game-label">Competitor Card</h4>
             {!opponentCard &&
                <img alt="" className="game-card-img2" src="assets/card.png"/>
              }
              {opponentCard &&
                <div style={{paddingTop:"4px"}} className="game-card-img2">
                  <CardImage static={true} fontSize={"14px"} img={opponentCard.img} poster={opponentCard.poster} vip={opponentCard.vip} id={opponentCard.id} offer={opponentCard.offer} game={opponentCard.game}/>
                </div>
              }
              </div>
              <div style={{width: "260px",padding:"0px"}}>
               <h4 className="game-label">Competitor Inventory</h4>
                <div className="game-inventory-area">
                <div className="profile-soon" style={{fontSize:"35px",lineHeight:"35px"}}>COMING<br/>SOON</div>
                </div>
              </div>
              <div>
                <h4 className="game-label"> </h4>
                <div style={{width: "100%",padding:"10px",paddingTop: "10px",marginTop:"10px"}} className="game-team-area">
                <img style={{width:"90%"}} src="/assets/game/arena0/textright1.png" alt=""/>
                </div>
                <div style={{left:"10%"}} className="game-triangle2">
                  <div className="game-triangle-inner2"></div>
                </div>  
              </div>          
            </div>

          </div>
      </div>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default Game;

