import React, { useContext, useState } from 'react';
import web3 from '../../connection/web3';
//import NFTCollection from './NFTCollection';
import CollectionContext from '../../store/collection-context';
import Web3Context from '../../store/web3-context';
import ERC20 from '../../abis/erc20.json';
//import Pagination from '../Layout/Pagination';
//import Switch from "react-switch";
//import CollectionProvider from '../../store/CollectionProvider';
import Modal from 'react-modal';
import PetControl from '../Layout/PetControl'
import Card from '../Layout/Card';
let vvtAddress = [];
vvtAddress[820] = "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53";
vvtAddress[119] = "0x118dF330c1a2DAa667c5e8c7716cdc89C153a986";
const Main = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext); 
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modal2IsOpen, setIsOpen2] = React.useState(false);
  const [gameType, setGameType] = React.useState(0);
  const [gameTime, setGameTime] = React.useState(0);
  const [gameArena, setGameArena] = React.useState(0);
  const [players, setPlayers] = React.useState(2);
  const [name, setName] = React.useState("");
  const [cost, setCost] = React.useState(10);
  const arenaLabels = ["Petztagram","Petzbook","Petzter","PetzTok"];
  const gameLabels = ["1 vs 1", "Team vs Team", "Selfie"];
  const timeLabels = ["2 minutes","5 minutes", "15 minutes", "30 minutes","1 hour","24 hours"];
  const timeValues= ["120","300", "900", "1800","3600","86400"];
  const [helperState, setHelperState] = useState(0);
   function onlyLatinSpacesDots(str) {
    return /^[0-9a-zA-Z\s.,]+$/.test(str);
  }
  function openModal() {
    setIsOpen(true);
  }
  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal2() {
    setIsOpen2(false);
  }
  function checkCreate(){
    if(name.length<4 || !onlyLatinSpacesDots(name)){
      alert("Game name must be 3-14 characters and only Latin characters");
      return;
    }
    if(parseInt(cost)<10){
      alert("Minimum Game fee is 10 VVT");
      return;
    }
    closeModal();openModal2()
  }
  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };
  /*const switchNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.switchToCallisto(web3);
  };*/
    const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };
  const createArena = async(activeGameCard) => {  
    if(activeGameCard == null){
      return;
    }
    let _cost = web3.utils.toWei(cost.toString(), 'ether');
    let VVTcontract = new web3.eth.Contract(ERC20.abi, vvtAddress[web3Ctx.networkId]);//vvt
    let allowance = await VVTcontract.methods.allowance(web3Ctx.account,collectionCtx.gameContract.options.address).call();
    
    if(parseInt(allowance)>parseInt(_cost)){
      await collectionCtx.gameContract.methods.createGame(name, _cost,gameType,players, gameArena,activeGameCard.game.cardId,timeValues[gameTime]).send({ from: web3Ctx.account });
      alert("Game creating in the blockchain, will be available in 10-15 seconds");
    }else{
      VVTcontract.methods.approve(collectionCtx.gameContract.options.address,web3.utils.toWei("1000000")).send({ from: web3Ctx.account }).on('transactionHash',()=>{ 
      }).on('receipt', async(hash) => {
        await collectionCtx.gameContract.methods.createGame(name, _cost,gameType,players, gameArena,activeGameCard.game.cardId,timeValues[gameTime]).send({ from: web3Ctx.account});
        alert("Game creating in the blockchain, will be available in 10-15 seconds");
      }).on('error', (error) => {
        window.alert('Something went wrong when pushing to the blockchain');
      })
    }
  };
  let games= collectionCtx.arenas;
  
  let data= collectionCtx.collection.concat(collectionCtx.petzCollection).filter(NFT=>{
        const onSale = (NFT.offer != null && NFT.offer.id == NFT.id) ? true : false;
        const inGame = (NFT.game != null && NFT.game.inGame) ? true : false;
        const owner = !onSale ? (inGame ? NFT.game.owner : NFT.owner) : NFT.offer.user;
        if(web3Ctx.account != owner){
          return false;
        }
        return inGame && NFT.game.gameId == 0;
      }).sort((a, b) => a.id < b.id? 1 : -1)
  return(
    <div>
    { !web3 &&
      <div id="section">
        <div className="container">
          <p className="p-3">You need a DApp compatible wallet to play. You can install <a href="https://metamask.io/">Metamask</a></p>
        </div>
      </div>
    }
    { (web3Ctx && web3Ctx.networkId && web3Ctx.networkId != 820 ) &&
      <div id="section">
        <div className="container">
          <button type="button" className="nfts-btn" onClick={addNetwork} >Switch to Callisto Network in wallet</button>     
        </div>
      </div>
    }
    { (web3 && web3Ctx && !web3Ctx.account ) &&
      <div id="section">
        <div className="container">
          <button type="button" className="nfts-btn" onClick={connectWallet} >Connect Wallet to play</button> 
        </div>
    </div>
    }
    { (web3Ctx.account && web3Ctx.networkId == 820 ) &&
    <React.Fragment>
        <Modal className="arena-modal" overlayClassName="Overlay-arena" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Rules">
    <img alt="" className="rules-close" src="assets/x2.png" onClick={closeModal}/>
        <div className="generator-wrapper col">
          <div className="col arena-inputs">
            <img alt="" src="assets/arenaLeft.png" className="generator-inputs-img"/>
            <label className="profile-inputs-label">GAME NAME</label>
            <input  onFocus={(e) =>{setHelperState(1)}} value={name} onChange={e=>{if(e.target.value.length>13){return;}setName(e.target.value);}} className="lottery-input"/>
            <label style={{marginTop: "10px"}} className="profile-inputs-label">ARENA</label>
            <PetControl disabled={true} label={arenaLabels[gameArena]} min={0} max={0} onChange={(e) => setGameArena(e)} value={gameArena}  />
            <label style={{marginTop: "-10px"}}  className="profile-inputs-label">GAME TYPE</label>
            <PetControl disabled={true} label={gameLabels[gameType]}  min={0} max={0} onChange={(e) => setGameType(e)} value={gameType} />
          </div>
          <div style={{marginTop: "30px"}} className={`col generator-card`}>
            <img alt="" className="petz-item" src="assets/profileItem.png"/>
              <img alt="" className="lottery-img" src={`/assets/arena/${gameArena}${gameType}.png`} />
            <div style={{ width:"100%",marginTop:"-30px"}} className="profile-row">
              <button onClick={checkCreate} style={{ width:"76%",height:"47px"}} className="item-btn petz-buy profile-save">Create</button>
            </div>
            <div className="lottery-bubble">
              <img alt="" src={`/assets/helpers/game/create/${helperState}.png`}/>
            </div>
          </div>
          <div className="col arena-inputs arena-inputs-right">
              <img alt="" src="assets/arenaRight.png" className="generator-inputs-img"/>
              <label className="profile-inputs-label">NUMBER OF PLAYERS</label>
              <PetControl disabled={true} min={2} max={2} onChange={(e) => setPlayers(e)} value={players} label={players} />
              <label style={{marginTop:"-20px"}} className="profile-inputs-label">ROUND TIME</label>
              <PetControl min={0} max={5} onChange={(e) => setGameTime(e)} value={gameTime} label={timeLabels[gameTime]} />
              <label style={{marginTop: "-50px"}} className="profile-inputs-label">JOIN COST</label>
              <input type="number" min={10} value={cost} onChange={e=>{setCost(e.target.value);}} style={{width: "100%",marginBottom: "20px"}} className="lottery-input"/>
          </div>
      </div>
   </Modal>
   <Modal className="arena-modal" overlayClassName="Overlay-arena" isOpen={modal2IsOpen} onRequestClose={closeModal2} contentLabel="Rules">
    <img alt="" className="rules-close" src="assets/x2.png" onClick={closeModal2}/>
            <div style={{overflowY: "scroll",height:"92%"}} className="row text-center">
            { 
              data.filter(NFT=>{
                return NFT.game.gameId == 0;
              }).map((NFT, _key) => {
              return(
                <Card onClick={nft=>{closeModal2();createArena(nft);}} hideButtons={true} vip={!NFT.pet} key={NFT.pet?(NFT.id+1000):NFT.id} game={NFT.game} id={NFT.id} offer={NFT.offer} img={NFT.img} owner={NFT.owner} web3Ctx={web3Ctx} buyHandler={()=>{}} makeOfferHandler={()=>{}} 
                cancelHandler={()=>{}} />
              )})
            }

            </div>
            <div style={{marginLeft:"auto",marginRight:"auto"}} className="lottery-bubble">
              <img alt="" src={`/assets/helpers/game/create/2.png`}/>
            </div>

   </Modal>
        <section className="mb-5">
        <img alt="" className="market-back" src="assets/marketBack.png"/>
        <div className="container">
          <div className="quick-nav my-3">
            </div>
        </div>
    </section>
    <div id="section">
        <div className="container">
            <div className="item-grid">
              <div className="row text-center item-row">

         <div className="item-wrapper">
                     <div className="item">
                        <img alt="" className="item-images main-img" src="assets/arena/create.png"/>                         
                     </div>
                      {                      
                        <button onClick={openModal} style={{width:'80%' }} className="item-btn group ">Create</button>
                      }
                     </div>
                     { 

              games.slice(0,25)
              //.filter(data => data.status!=2 )
              .sort((a, b) => a.status == b.status? 0: (a.status > b.status ? 1 : -1)).map((game, _key) => {
              let size="22px";
              let fee = game.fee/10**18;
              let bank = fee * game.users;
              let labelFee = "";
              //let labelBank = "";
              if(fee == 0){
                fee = "free";
                bank = "0";
              }else{
                let sign = "";
                //console.log(fee,fee%1000);
                if(fee>1000){
                  fee/=1000;
                  sign=" th";
                  size = "18px";
                }
                if(fee>1000){
                  fee/=1000;
                  sign=" m";
                  size = "18px";
                }
                fee = Math.round(fee);
                labelFee = sign +" VVT";
                sign = "";
                if(bank>1000){
                  bank/=1000;
                  sign=" th";
                  size = "18px";
                }
                if(bank>1000){
                  bank/=1000;
                  sign=" m";
                  size = "18px";
                }
                bank = Math.round(bank);
                //labelBank = sign +" CLO";
              }
              return(
              <div className={`item-wrapper`}>
                <div className="item">
                  <img alt="" className={`item-images main-img`} src={`/assets/arena/${game.arena}${game.gameType}.png`} />  
                  <div className="arena-label-name">{game.name}</div>  
                  <div className="arena-label-area">{arenaLabels[game.arena]}</div> 
                  <div style={{fontSize:size}} className="arena-label-fee">{fee}<span style={{fontSize:"60%"}}>{labelFee}</span></div> 
                  <div style={{fontSize:size}} className="arena-label-bank">{bank}<span style={{fontSize:"60%"}}>{labelFee}</span></div> 
                  <div className="arena-label-users">{game.users}/{game.maxUsers}</div>                    
                </div>
                { game.status==0 && game.owner === web3Ctx.account &&                      
                    <button onClick={(e) => {e.preventDefault();window.location.href=`/#/play/${game.id}`}} style={{width:'80%' }} className={"item-btn group "}>View</button>
                }
                { game.status==0 && game.owner !== web3Ctx.account &&                      
                    <button onClick={(e) => {e.preventDefault();window.location.href=`/#/play/${game.id}`}} style={{width:'80%' }} className={"item-btn group "}>Join</button>
                }
                { game.status ==1 && game.owner == web3Ctx.account &&                      
                    <button onClick={(e) => {e.preventDefault();window.location.href=`/#/play/${game.id}`}} style={{width:'80%' }} className={"item-btn group "}>Play</button>
                }
                { game.status ==1 && game.owner !== web3Ctx.account &&                      
                    <button onClick={(e) => {e.preventDefault();window.location.href=`/#/play/${game.id}`}} style={{width:'80%' }} className={"item-btn group "}>View</button>
                }
                { game.status==2 &&                      
                    <button onClick={(e) => {e.preventDefault();window.location.href=`/#/play/${game.id}`}} style={{width:'80%' }} className={"item-btn group "}>Results</button>
                }
               </div>
              )})
            }
      </div>  
             
            </div>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default Main;