import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';


class PetControl extends Component {

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  componentDidUpdate(props) {

  }

  updateData = val => {
    const { onChange = f => f } = this.props;
    let newVal = this.props.value + val;
    if(newVal>this.props.max){
      newVal = this.props.min;
    }
    if(newVal <this.props.min){
      newVal = this.props.max;
    }
    console.log(newVal,this.props);
    this.setState({ value:newVal }, () => onChange(newVal));
  }

  render() {



    return (
        <div onMouseEnter={(e) => {e.preventDefault();if(this.props.onMouseEnter){this.props.onMouseEnter()}}} className={`petz-control ${this.props.class?this.props.class:""}`}>
          {this.props.disabled && <img onClick={(e) => {e.preventDefault();this.updateData(-1)}} className="petz-btn petz-btn-disabled" src="assets/arrowd.png" />}
          {!this.props.disabled && <img onClick={(e) => {e.preventDefault();this.updateData(-1)}} className="petz-btn" src="assets/arrow.png" />}
          <span >{this.props.label}</span>
           {this.props.disabled && <img onClick={(e) => {e.preventDefault();this.updateData(1)}} className="petz-btn petz-btn-disabled" src="assets/arrowd.png" />}
           {!this.props.disabled && <img onClick={(e) => {e.preventDefault();this.updateData(1)}} className="petz-btn" src="assets/arrow.png" />}
        </div>
    );

  }
}


export default PetControl;