import React from 'react';

const CollectionContext = React.createContext({
  gameContract:null,
  icoContract:null,
  bridgeContract:null,
  farmContract:null,
  nftContract: null,
  marketContract: null,
  petzContract: null,
  petzMarketContract: null,
  userFunds: null,
  petFunds: null,
  gameFunds: null,
  totalSupply: null,
  petzTotalSupply:null,
  collection: [],
  petzCollection: [],
  nftIsLoading: true,
  petzPrice:0,
  arenas:null,
  loadContract: () => {},
  loadPetzContract: () => {},
  loadTotalSupply: () => {},
  loadCollection: () => {},
  updateTotalSupply: () => {},
  updateCollection: () => {},
  updateOwner: () => {},
  updateOffer: () =>{},
  updatePetzTotalSupply: () => {},
  updatePetzCollection: () => {},
  updatePetzOwner: () => {},
  updatePetzOffer: () =>{}
});

export default CollectionContext;