import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import React  from 'react';

import Web3Provider from './store/Web3Provider';
import CollectionProvider from './store/CollectionProvider';
import App from './App';
import {HashRouter as Router} from 'react-router-dom';

ReactDOM.render(
  <Web3Provider>
    <CollectionProvider>
        <Router>
          <App />
        </Router>
    </CollectionProvider>
  </Web3Provider>, 
  document.getElementById('root')
);