import { useContext, useState,useEffect } from 'react';
import React  from 'react';
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import web3 from '../../connection/web3';
import { formatPrice } from '../../helpers/utils';
import { Dropdown  } from 'react-bootstrap';

import { Link,NavLink,useSearchParams } from "react-router-dom";
import {useLocation} from "react-router-dom";
const Topbar = () => {

  const [fundsLoading, setFundsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const web3Ctx = useContext(Web3Context);
  const marketplaceCtx = useContext(CollectionContext);
  var networks =new Array();
  networks[820] = "Callisto";
  networks[56] = "Binance";
  networks[119] = "ENULS";
    const [pendingVVT, setPendingVVT] = useState(0);


    useEffect(() => {
    const interval = setInterval(() => {
        if(!marketplaceCtx.gameContract){
          return;
    }
        if(!web3Ctx ||!web3Ctx.account){
        return;
    }
    if(marketplaceCtx.farmContract && web3Ctx.networkId == 820){
        marketplaceCtx.farmContract.methods.pendingReward(web3Ctx.account).call().then(data=>{
          setPendingVVT(parseInt(data)/10**18);
        });
    }
  }, 30000);
  return () => clearInterval(interval);
}, [marketplaceCtx]);
    useEffect(()=>{
    if(!marketplaceCtx.gameContract){
      return;
    }
    if(!web3Ctx ||!web3Ctx.account){
        return;
    }
    if(marketplaceCtx.farmContract && web3Ctx.networkId == 820){
        marketplaceCtx.farmContract.methods.pendingReward(web3Ctx.account).call().then(data=>{
          setPendingVVT(parseInt(data)/10**18);
        });
    }
    
    },[marketplaceCtx.gameContract, web3Ctx.account])
  const connectWalletHandler = async() => {
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
    }
    // Load accounts
    web3Ctx.loadAccount(web3);
  };

   const switchNetwok = async(networkId) => {
      if(!web3Ctx){
        return;
      }
      if(networkId == 820){
          web3Ctx.switchToCallisto(web3);
      }
      if(networkId == 56){
          web3Ctx.switchToBSC(web3);
      }
  };
  const claimVVTHandler= async() => {  
    marketplaceCtx.farmContract.methods.claim().send({ from: web3Ctx.account });
  };
  const claimGameHandler = async() => {
    setFundsLoading(true);
    if(marketplaceCtx.gameFunds && marketplaceCtx.gameFunds>0){
       await marketplaceCtx.gameContract.methods.claimFunds().send({ from: web3Ctx.account })
    }
    marketplaceCtx.loadUserFunds(web3Ctx.account);
    setFundsLoading(false); 
  };
  const claimFundsHandler = async() => {
    setFundsLoading(true);
    if(marketplaceCtx.userFunds && marketplaceCtx.userFunds>0){
        await marketplaceCtx.marketContract.methods.claimFunds().send({ from: web3Ctx.account })
    }
    if(marketplaceCtx.petFunds && marketplaceCtx.petFunds>0){
        await marketplaceCtx.petzMarketContract.methods.claimFunds().send({ from: web3Ctx.account })
    }

    marketplaceCtx.loadUserFunds(web3Ctx.account);
    setFundsLoading(false); 
  };
    let location = useLocation();
  useEffect(() => {
      if(location.key == "default"){
          return;
      }
      window.gtag('event', 'page_view', {
      page_title: location.pathname,
      page_location: location.pathname + location.search ,
      page_path: location.pathname ,
      send_to: 'G-14H6DPRY6S'
    })
  }, [location]);

useEffect(() => {    
       let ref = searchParams.get("ref");
       if(ref && ref.length>0){
           ref = parseInt(ref);
           if(ref>0){
               localStorage["ref"] = ref;
           }
       }
      }, []);
  return (
    <header id="header">
        <nav className="navbar navbar-expand-md navbar-light">
            <div className="container">
                <Link id="logo" className="navbar-brand" to="/"><img src="assets/logo.png" alt="Logo/"/></Link>
                <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul id="navigation" className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/market">market</NavLink>
                        </li>
                        <li className="desktop nav-item">
                            <NavLink className="nav-link" to="/createpet">mint BlogPetz</NavLink>
                        </li>
                        <li className="mobile nav-item">
                            <NavLink className="nav-link" to="/createpet">BlogPetz</NavLink>
                        </li>
                        <li className="desktop nav-item">
                            <NavLink className="nav-link" to="/profile">profile</NavLink>
                        </li>                        
                        <li className="desktop nav-item">
                            <NavLink className="nav-link" to="/play">play</NavLink>
                        </li>
                        <li className="desktop nav-item">
                            <NavLink className="nav-link" to="/registerref">referrals</NavLink>
                        </li>
                    </ul>
                    <div className="d-flex">
                    {
                <Dropdown className="desktop network-drop">
                <Dropdown.Toggle className="network-btn"><img className="network-img" src={`/assets/chains/${web3Ctx.networkId}.png`}/>{networks[web3Ctx.networkId]?networks[web3Ctx.networkId]: "Unknown"}</Dropdown.Toggle>
                <Dropdown.Menu className="network-menu">
                  <Dropdown.Item onClick={() => switchNetwok(820)} key="Callisto"><img className="network-img" src="/assets/chains/820.png"/>Callisto Network</Dropdown.Item>
                  <Dropdown.Item onClick={() => switchNetwok(119)} key="enuls"><img className="network-img" src="/assets/chains/119.png"/>ENULS Chain</Dropdown.Item>
                  <Dropdown.Item onClick={() => switchNetwok(56)} key="binance"><img className="network-img" src="/assets/chains/56.png"/>Binance Smart Chain</Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
                    }
                       { /*((marketplaceCtx.userFunds && marketplaceCtx.userFunds > 0) || (marketplaceCtx.petFunds && marketplaceCtx.petFunds > 0)  || (marketplaceCtx.gameFunds && marketplaceCtx.gameFunds > 0) ) &&
                         <button type="button" style={{fontFamily: "'Open Sans', sans-serif",fontSize:"12px"}} className={`wallet-btn top-button nfts-btn btn-pink ${fundsLoading ? "disabled" : ""}`} onClick={()=>claimFundsHandler()} >          
                            {`CLAIM ${formatPrice(parseInt(marketplaceCtx.userFunds)+parseInt(marketplaceCtx.petFunds)+parseInt(marketplaceCtx.gameFunds) )} CLO`}
                          </button>*/
                        }
                       {web3Ctx.account &&
                           //&& (!marketplaceCtx.userFunds || marketplaceCtx.userFunds === "0") && (!marketplaceCtx.petFunds || marketplaceCtx.petFunds === "0") && (!marketplaceCtx.gameFunds || marketplaceCtx.gameFunds === "0") &&  
                        <Dropdown className="wallet-btn top-button ">
                          <Dropdown.Toggle className="wallet-style">{`${web3Ctx.account.substr(0,6)}...${web3Ctx.account.substr(web3Ctx.account.length - 4)}`}</Dropdown.Toggle>
                              <Dropdown.Menu className="network-menu">
                              <Dropdown.Item onClick={() => claimVVTHandler()} key="markets">{`Claim ${pendingVVT.toFixed(4)} VVT from Farm`}</Dropdown.Item>
                              {(marketplaceCtx.userFunds && marketplaceCtx.userFunds > 0) || (marketplaceCtx.petFunds && marketplaceCtx.petFunds > 0) && <Dropdown.Item onClick={() => claimFundsHandler()} key="markets">{`Claim ${formatPrice(parseInt(marketplaceCtx.userFunds)+parseInt(marketplaceCtx.petFunds))} CLO from Markets`}</Dropdown.Item>}
                              {(marketplaceCtx.gameFunds && marketplaceCtx.gameFunds > 0) && <Dropdown.Item onClick={() => claimGameHandler()} key="game">{`Claim ${formatPrice(parseInt(marketplaceCtx.gameFunds))} VVT from Game`}</Dropdown.Item>   }   
                </Dropdown.Menu>
              </Dropdown>
                        }
                        {!web3Ctx.account && 
                          <button type="button" className="wallet-btn top-button nfts-btn" onClick={connectWalletHandler} >Connect</button>        
                         }  
                        
                    </div>
                </div>
            </div>
        </nav>
    </header>
  );  
};

export default Topbar;