import React, {  useState } from 'react';
import { Link } from "react-router-dom";

const Main = () => {
const [showMore, setShowMore] = useState(false);

  return(
    <React.Fragment>
    <section id="welcome">
        <div className="container">
            <div className="row" style={{position:"relative"}}>

                <div className="col-6 box-left">
                    <p className="welcome-text p-3 pInfo">
A Very Important Pets, like real celebrities
fighting not for food and home. They are fighting for hype. They challenge each
other, write nasty comments on social
media, participate in evening shows, blame 
                    </p>
                </div>
                <div className="col-6 box-right">
                    <p className="welcome-text p-3 pInfo">
and create disrespect tracks.
The popularity of these Very Important Pets gives hundreds of millions dollars to their owners.
Choose your pet and let it earn for your luxurious life. Let the  VIP Warz begin.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section id="banner" className="my-0">
        <img alt="" className="img-banner" src="assets/banner.png"/>
        <img alt="" className="img-banner-mobile" src="assets/bannerMobile.png"/>
    <img alt="" className="icon1 smile" src="assets/icon1.gif"/>
    <img alt="" className="icon2 smile" src="assets/icon2.gif"/>
                <Link className="box market-box market-link" to="/market"></Link>
    </section>
    <section id="info">
            <div className="container"><div className="row">
             <h1 className="roadmap-title info-paragraph">Why VIPWarz?</h1>
<h2 className="info-paragraph">Transparency and fair play are our priorities</h2>
<p className="info-paragraph">Market analysis shows that many blockchain games use the Smart Contracts only for game tokens, implementing game logic at the client level. 
Such concepts hide financial risks for users and give the ability for developers to change the rules in real time.
</p>
<p className="info-paragraph">
We will make VIPWarz the first game to fully implement game logic in Smart Contracts of the Callisto network. 
This will protect users from manipulation by the developers and make the game process completely transparent.
</p>
<h2 className="info-paragraph">Fair play - fair Callisto commission</h2>
<p className="info-paragraph">The average gas price when working with a smart contract (operations with equipment, battle attack or defence actions as example ) in the Binnance Smart Chain 
will be more than $10 and more than $150 in the Ethereum chain.</p>
<p className="info-paragraph">The Callisto network fully implements EVM, block time is less than 13 seconds and Gas price will be less than 1 cent.
Also, the Callisto network has a cross-chain bridge, which makes it easy to bring assets from other active networks. That's why we choose Callisto Network for our project.
</p>

<h2 className="info-paragraph">Play for fun and earn</h2>
<p className="info-paragraph">VIPWarz is an online RPG with game mechanics that stimulates the value of cards and an exciting storyline. 
Let Very Important Pets fight for hype in pet social networks and the media space. And you will make a profit - just train your pet.
</p>
<p className="info-paragraph">
Each card has basic characteristics that are stored in the NFT smart contract and can be updated by the game smart contract. 
It is the number of likes, the number of dislikes and stress resistance.&nbsp;
{ !showMore && <div className="more-link" onClick={() => setShowMore(!showMore)} >More...</div>

}
</p>
{ showMore &&
    <React.Fragment>
<p className="info-paragraph">
<ul>
<li>The number of likes shows the character's ability to attack - this is his fan base, ready to support in the pets media war.</li>
<li>The number of dislikes shows the character's ability to defend himself - how many dislikes(opponent attacks) he can withstand until his stress level rises.</li>
<li>Stress, shows the "health" of the player. The player loses battle as soon as the stress level reaches 10.</li>
</ul>
</p>

<p className="info-paragraph">Take part in battles to increase the value of the card.</p>
<p className="info-paragraph">
<ul>
<li>Duel:  Any player can create a 1:1 fight and another player can accept this duel.</li>
<li>King of the hype:  Everybody against all. One of the players creates a battle, indicating the number of players. 
The battle begins when all players have joined. The winner of the battle is a single player who defeats all others. </li>
<li>Clan warz - some player creates a battle, indicating the number of players and number of teams. The battle begins when all players have joined. The winner is the team that beats the other teams.</li>
</ul>
</p>
<p className="info-paragraph">
Win the battles - get XP and improve the characteristics of your card. You can also buy XP for a VWI token or upgrade your character using equipment that can be bought, obtained in battle or exchanged from other players. All this increases the cost of the card, which means your profit.
</p>
</React.Fragment>
}
</div>
</div>
    </section>
    <section id="roadmap">
        <div className="container">
            <div className="roadmap-inner">
                <h1 className="roadmap-title mt-2"> ROADMAP </h1>
                 <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">Q2-Q3 2023</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">                                
                            <ul>
                                <li>Listings on CEX’s</li>
                                <li>Attracting more independent devs to develop in VIPSVERSE metaverse using DAO dev fund </li>
                                <li>Mobile version of game </li>
                                </ul> 
                                 </p>
                        </div>
                    </div>
                </div>
                 <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">Q1-Q2 2023</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">
                                <ul>
                                <li>More projects developed in the VIPSVERSE metaverse by team and independent devs using DAO Dev fund</li>
                                </ul>   
                            </p>
                        </div>
                    </div>
                </div>
                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">Q4 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">
                            <ul>                                
                                <li>End of ICO </li>
                                <li>Farming with NFT</li>
                            </ul>   
                            </p>
                        </div>
                    </div>
                </div>
                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">Q3 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">
                            <ul>
                                <li>VVT Token and NFTs available via bridge</li>
                                <li>Migration to CallistoNFT standard</li>
                                <li>VVT ICO - Phase 2 Bittorrent Chain </li>
                                <li>VVT ICO - Phase 3 Ethereum Classic</li>
                                <li>VVT ICO - Phase 4 Binance Smart Chain </li>
                                <li>Game v1 official start</li>
                                <li>Inventory market available</li>
                                <li>VVT available on Soy.finance</li>
                            </ul>
                        </p>
                        </div>
                    </div>
                </div>
                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">June 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">VVT ICO - Phase 1 Callisto Network</p>
                        </div>
                    </div>
                </div>

                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">April 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">Game public beta</p>
                        </div>
                    </div>
                </div>

                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">February 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">BlogPetz launched</p>
                        </div>
                    </div>
                </div>

                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">January 2022</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">Bundles introduced </p>
                        </div>
                    </div>
                </div>

                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">December 2021</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">First Legendary Cards Available</p>
                        </div>
                    </div>
                </div>

                <div className="timeline"> 
                    <div className="timeline-icon"><img src="assets/icon2.png" alt="Icon"/></div>
                    <div className="timeline-info">
                        <div className="timeline-year">
                            <h1 className="p-0 m-0">November 2021</h1>
                        </div>
                        <div className="timeline-desc">
                            <p className="mb-0 pb-0">Official Launch</p>
                        </div>
                    </div>
                </div>                       
            </div>
            <div className="roadmap-inner">
            <h1 className="desktop roadmap-title " style={{marginTop:"80px"}}> TEAM </h1>
            <h1 className="mobile roadmap-title " style={{marginTop:"40px"}}> TEAM </h1>
            </div>
          <img alt="" className="desktop" src="/assets/team/desktop.png"/>
          <img alt="" className="mobile" src="/assets/team/mobile.png"/>
        </div>
    </section>

    </React.Fragment>
  
  );
};

export default Main;