import React, { useContext, useEffect } from 'react';
import {Routes,Route, useLocation} from 'react-router-dom';
import web3 from './connection/web3';
import Web3Context from './store/web3-context';
import Topbar from './components/Layout/Topbar';
import CollectionContext from './store/collection-context';

import Market from './components/Content/Market';
import Play from './components/Content/Play';
//import ICO from './components/Content/ico';
import Profile from './components/Content/Profile';
import Landing from './components/Content/Landing'
import Game from './components/Content/Game'
import CreatePet from './components/Content/createPet'
import RegisterRef from './components/Content/registerRef'
import Privacy from './components/Content/Privacy'
import Terms from './components/Content/Terms'
import Team from './components/Content/Team'
import { MdOutlinePrivacyTip } from 'react-icons/md';

//import { createBrowserHistory } from 'history';
import { matchPath } from "react-router";




const App = () => {

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const location = useLocation();
  
  
  const loadBlockchainData = async() => {
      let account = await web3Ctx.loadAccount(web3);
      await web3Ctx.loadNetworkId(web3);
      await collectionCtx.loadPetzContract(web3,account);
      collectionCtx.loadContract(web3,account);      
    };


  useEffect(() => {    
     try {
        if(window.ethereum){//todo, subcribe once
          window.ethereum.on('accountsChanged', async(accounts) => {
           //loadBlockchainData();
           let account = await web3Ctx.loadAccount(web3);
           collectionCtx.loadUserFunds(account);
          });
          window.ethereum.on('chainChanged', (chainId) => {
            console.log("chain change:",chainId);
            if(parseInt(chainId) === 820){
              loadBlockchainData();
              web3Ctx.loadAccount(web3);
            };
          });
          };
      } catch(error) {console.error(error);}
    
    loadBlockchainData();
  }, []);
const match = matchPath({
  path: "/play/:gameId",
  exact: true,
  strict: false
},location.pathname);
  return(
    <div style={{overflowX:"hidden"}} className={match?"instaBody":""}>
          <Topbar/>
      <Routes>
        <Route path="/" element={<Landing />}/>
        <Route path="/market" element={<Market/>}/>
        <Route path="/play" element={<Play/>}/>
        <Route path="/play/:gameId" element={<Game/>}/>
        <Route path="/terms" element={<Terms/>}/>
        <Route path="/createpet" element={<CreatePet/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/registerRef" element={<RegisterRef/>}/>
        <Route path="/team" element={<Team/>}/>
      </Routes>

          <footer>
        <div className="container py-4">
            <div className="row">
                <div className="col-8 copy">
                    <p className="copyright-text p-0 m-0">
                        &copy;VIPSVERSE OÜ 2023
                    </p>
                </div>
                <div className="col-4 linking">
                    <div className="social-link">
                        <a href="mailto:marketing@warz.vip" className="whitepaper"><i alt="Contact us" className="fa fa-envelope"></i></a> 
                        <a href="#/privacy" target="_newtab" className="whitepaper"><i className="privacy-icon"><MdOutlinePrivacyTip/></i></a>
                        <a href="https://vipwarz.gitbook.io/vipwarz" target="_newtab" className="whitepaper"><i alt="Whitepaper" className="fa fa-book"></i></a>
                        <a href="https://twitter.com/VipWarz" target="_newtab" className="twitter"><i className="fab fa-twitter"></i></a>
                        <a href="https://t.me/VipWarz" className="telegram" target="_newtab"><i className="fas fa-paper-plane"></i></a>
                        <a href="https://discord.gg/7rbkUJFDwf" className="discord" target="_newtab"><i className="fab fa-discord"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
  );
};

export default App;