import React, { useEffect, useContext, useRef, useState} from 'react';
import web3 from '../../connection/web3';

import Web3Context from '../../store/web3-context';
import ERC20 from '../../abis/erc20.json';
import {RangeStepInput} from 'react-range-step-input';
import Switch from "react-switch";
import CollectionContext from '../../store/collection-context';
import PetControl from '../Layout/PetControl'
import { DropdownButton,Dropdown  } from 'react-bootstrap';
import Card from '../Layout/Card';
import CardImage from '../Layout/CardImage';
import Modal from 'react-modal';
const BigNumber = require("bignumber.js");
const imageHandlerContract="0xC8CD614b7cdEef61A991C885EC7E4cdE2d5c3351";

let vvtAddress = [];
vvtAddress[820] = "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53";
vvtAddress[119] = "0x118dF330c1a2DAa667c5e8c7716cdc89C153a986";

const Profile = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext); 
  const [selectedGameTab, setSelectedGameTab] = useState(0);
  const [redraw, setRedraw] = useState(false);
  const [activeGameCard,setActiveGameCard] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [valueXP, setValueXP] = useState(1000);
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState(0);
  const [xpPrices, setXPPrices] = useState([0,0,0]);
  const [xpValues, setXPValues] = useState([0,0,0]);
  const [helperState, setHelperState] = useState(0);
  const [helperState2, setHelperState2] = useState(12);
  const [pendingVVT, setPendingVVT] = useState(0);

    let data=loadList();
    function loadList(){
      return collectionCtx.collection.concat(collectionCtx.petzCollection).filter(NFT=>{
        const onSale = (NFT.offer != null && NFT.offer.id == NFT.id) ? true : false;
        const inGame = (NFT.game != null && NFT.game.inGame) ? true : false;
        const owner = !onSale ? (inGame ? NFT.game.owner : NFT.owner) : NFT.offer.user;
        if(web3Ctx.account != owner){
          return false;
        }

        if(selectedGameTab == 0)
         {
           return !onSale && !inGame;
         }
         if(selectedGameTab == 2)
         {
           return onSale;
         }
         if(selectedGameTab == 1)
         {
           return inGame;
         }
         if(redraw){
         return true 
        }
      }).sort((a, b) => a.id < b.id? 1 : -1)
    }
  function openModal() {
    setHelperState2(12);
    loadPrices(activeGameCard);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function formatXPValue(value){
    value = parseInt(value);
    if(value >= 1000000){
      value = value/1000000 +"m";
    }else if(value >= 10000){
      value = value/1000 +"th";
    }

    return value;
  }
   useEffect(() => {
  }, );
   async function loadPrices(activeCard){
     console.log("load prices 1");
     if(!collectionCtx.gameContract || !activeCard){
       return;
     }
     console.log("load prices 2");
     if(!activeCard.game){
       setXPPrices([0,0,0]);
       setXPValues([0,0,0]);
       return;
     }
     console.log("load prices 3");
     let prices = [];
     prices[0] = await collectionCtx.gameContract.methods.getXPPrice(activeCard.game.cardId,0).call();
     prices[1] = await collectionCtx.gameContract.methods.getXPPrice(activeCard.game.cardId,1).call();
     prices[2] = await collectionCtx.gameContract.methods.getXPPrice(activeCard.game.cardId,2).call();
     setXPPrices(prices);
     let values = [];
     values[0] = formatXPValue(await collectionCtx.gameContract.methods.getXPValue(activeCard.game.cardId,0).call()*100);
     values[1] = formatXPValue(await collectionCtx.gameContract.methods.getXPValue(activeCard.game.cardId,1).call()*100);
     values[2] = formatXPValue((await collectionCtx.gameContract.methods.getXPValue(activeCard.game.cardId,2).call())/10);
     setXPValues(values);
   }
   useEffect(() => {
    const interval = setInterval(() => {
        if(!collectionCtx.gameContract){
          return;
    }
    collectionCtx.farmContract.methods.pendingReward(web3Ctx.account).call().then(data=>{
      setPendingVVT(parseInt(data)/10**18);
    });
  }, 30000);
  return () => clearInterval(interval);
}, [collectionCtx]);
useEffect(()=>{
    if(!collectionCtx.gameContract || !collectionCtx.gameContract.options.address){
      return;
    }

    //collectionCtx.farmContract.methods.pendingReward(web3Ctx.account).call().then(data=>{
      //setPendingVVT(parseInt(data)/10**18);
    //});
    //console.log(collectionCtx.gameContract);
    collectionCtx.gameContract.events.SpendXP().on('data', (event) => {
      setActiveGameCard(activeCard=>{
        loadPrices(activeCard);
        return activeCard;
      });
    });
    },[collectionCtx.gameContract])
  function onlyLatinSpacesDots(str) {
    return /^[a-zA-Z\s.,]+$/.test(str);
  }
  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };
  const addNetwork = async() => {
      if(!web3Ctx){
        return;
      }
      web3Ctx.addCallisto(web3);
  };
  const downloadHandler = async()=>{
    if(activeGameCard && activeGameCard.img){
  const image = await fetch(activeGameCard.img)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'VIPWarzCard.png'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
    }
  }
   const bridgeHandler = async()=>{
    if(activeGameCard == null){
      return;
    }
    if(activeGameCard.pet){
      return
    }
    if(web3Ctx.networkId ==820){
      let cost = await collectionCtx.bridgeContract.methods.getPriceInVVT().call()/10**18;
      if(!window.confirm(`Bridging from Callisto Network to Binance Smart Chain. \n You will be charged ${cost} VVT.\n Proceed?`)){
        return;
      }
    }else{
      if(!window.confirm(`Bridging from Binance Smart Chain to Callisto Network. \n Proceed?`)){
        return;
      }
    }
    let contract = collectionCtx.nftContract;
    if(activeGameCard.pet){
      contract = collectionCtx.petzContract;
    }
    contract.methods.approve(collectionCtx.bridgeContract.options.address,activeGameCard.id).send({ from: web3Ctx.account }).on('transactionHash',()=>{
      setStatus(4);
    })
    .on('receipt', (hash) => {
      if(web3Ctx.networkId ==820){
        let VVTcontract = new web3.eth.Contract(ERC20.abi, vvtAddress[web3Ctx.networkId]);//vvt
        VVTcontract.methods.approve(collectionCtx.bridgeContract.options.address,BigNumber(1000**18).toFixed()).send({ from: web3Ctx.account }).on('transactionHash',()=>{ 
        }).on('receipt', (hash) => {
          collectionCtx.bridgeContract.methods.depositNFTCallisto(activeGameCard.id).send({ from: web3Ctx.account }).on('transactionHash',()=>{
            setStatus(1);
          })
          .on('receipt', (hash) => {
            setStatus(2)
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
            setStatus(3);
          }); 
        }).on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
          setStatus(3);
        })
      }else{
        collectionCtx.bridgeContract.methods.depositNFT(activeGameCard.id).send({ from: web3Ctx.account }).on('transactionHash',()=>{
            setStatus(1);
          })
          .on('receipt', (hash) => {
            setStatus(2)
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
            setStatus(3);
          }); 
      }
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setStatus(3);
    });  
  }
  const setCardName = async(name)=>{
          collectionCtx.gameContract.methods.setPetGameName(activeGameCard.game.cardId,name).send({ from: web3Ctx.account }).on('transactionHash',()=>{
            setStatus(1);
          })
          .on('receipt', (hash) => {
            setStatus(2)
             if(activeGameCard.pet){
              let index = collectionCtx.petzCollection.findIndex(item => item.id === activeGameCard.id);               
              collectionCtx.petzCollection[index].game.name = name;
              collectionCtx.petzCollection[index].img = collectionCtx.petzCollection[index].img+"#";
              collectionCtx.petzCollection = [...collectionCtx.petzCollection];
              setSelectedGameTab(4);
              setSelectedGameTab(1);
              setActiveGameCard({...collectionCtx.petzCollection[index]});
            }
            else{
              let index = collectionCtx.collection.findIndex(item => item.id === activeGameCard.id);               
              collectionCtx.collection[index].game.name = name;
              collectionCtx.collection[index].img = collectionCtx.collection[index].img+"#";
              collectionCtx.collection = [...collectionCtx.collection];
              setSelectedGameTab(4);
              setSelectedGameTab(1);
              setActiveGameCard({...collectionCtx.collection[index]});
            }
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
            setStatus(3);
          }); 
  }

  const updateName = async() => {  
    if(name.length<3 || !onlyLatinSpacesDots(name)){
      alert("Name must be 3-14 characters and only Latin characters");
      return;
    }
     let VVTcontract = new web3.eth.Contract(ERC20.abi, vvtAddress[web3Ctx.networkId]);//vvt
     let allowance = await VVTcontract.methods.allowance(web3Ctx.account,collectionCtx.gameContract.options.address).call();
     if(allowance>(10*10**18)){
       setCardName(name);
     }else{
       VVTcontract.methods.approve(collectionCtx.gameContract.options.address,web3.utils.toWei("1000000")).send({ from: web3Ctx.account }).on('transactionHash',()=>{ 
       }).on('receipt', (hash) => {
           setCardName(name);
          }).on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
            setStatus(3);
          })
      }
  }; 
  const useXP = async(target) => {  
    if(activeGameCard == null){
      return;
    }
    collectionCtx.gameContract.methods.spendXP(activeGameCard.game.cardId,target).send({ from: web3Ctx.account }).on('transactionHash',()=>{
      setStatus(1);
    })
    .on('receipt', (hash) => {
      setStatus(2)
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setStatus(3);
    });  ;
  }; 

  const buyXPHandler = async() => {  
    if(activeGameCard == null){
      return;
    }
    let _cost = web3.utils.toWei(valueXP.toString(), 'ether');
    let VVTcontract = new web3.eth.Contract(ERC20.abi, vvtAddress[web3Ctx.networkId]);//vvt
    let allowance = await VVTcontract.methods.allowance(web3Ctx.account,collectionCtx.gameContract.options.address).call();
    
    if(parseInt(allowance)>parseInt(_cost)){
      await collectionCtx.gameContract.methods.buyXP(activeGameCard.game.cardId,valueXP).send({ from: web3Ctx.account});
      loadPrices(activeGameCard);
    }else{
      VVTcontract.methods.approve(collectionCtx.gameContract.options.address,web3.utils.toWei("1000000")).send({ from: web3Ctx.account }).on('transactionHash',()=>{ 
      }).on('receipt', async(hash) => {
        await collectionCtx.gameContract.methods.buyXP(activeGameCard.game.cardId,valueXP).send({ from: web3Ctx.account});
        loadPrices(activeGameCard);
      }).on('error', (error) => {
        window.alert('Something went wrong when pushing to the blockchain');
       })
    }
  };
  const addToGame = async() => {  
    if(activeGameCard == null){
      return;
    }
    /*if(!activeGameCard.pet){
      alert('Only BlogPetz allowed in Beta');
      return;
    }*/
    let contract = collectionCtx.nftContract;
    if(activeGameCard.pet){
      contract = collectionCtx.petzContract;
    }
    contract.methods.approve(collectionCtx.gameContract.options.address,activeGameCard.id).send({ from: web3Ctx.account }).on('transactionHash',()=>{
      setStatus(4);
    })
    .on('receipt', (hash) => {
      collectionCtx.gameContract.methods.addToContract(activeGameCard.id,contract.options.address).send({ from: web3Ctx.account }).on('transactionHash',()=>{
        setStatus(1);
      })
      .on('receipt', (hash) => {
        setSelectedGameTab(1);
        setHelperState(2);
        setActiveGameCard(null);
        setStatus(2)
      })
      .on('error', (error) => {
        window.alert('Something went wrong when pushing to the blockchain');
        setStatus(3);
      }); 
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setStatus(3);
    });  
  };
  const transferHandler= async() => {  
    if(activeGameCard == null){
      return;
    }
    let contract = collectionCtx.nftContract;
    if(activeGameCard.pet){
      contract = collectionCtx.petzContract;
    }
    contract.methods.transferFrom(web3Ctx.account,address,activeGameCard.id).send({ from: web3Ctx.account });
  };

  const removeFromGame = async() => {  
    if(activeGameCard == null){
      return;
    }
    let contract = collectionCtx.nftContract;
    if(activeGameCard.pet){
      contract = collectionCtx.petzContract;
    }
    collectionCtx.gameContract.methods.removeFromContract(activeGameCard.id,contract.options.address).send({ from: web3Ctx.account }).on('transactionHash',()=>{
      setStatus(1);
    })
    .on('receipt', (hash) => {
      setSelectedGameTab(0);
      setHelperState(1);
      setActiveGameCard(null);
      setStatus(2)
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setStatus(3);
    });  ;
  };
  const cancelHandler = () => {  
    if(activeGameCard == null){
      return;
    }
    const onSale = (activeGameCard.offer != null && activeGameCard.offer.id == activeGameCard.id) ? true : false;
    if(!onSale){
      return;
    }
    let contract = collectionCtx.marketContract;
    if(activeGameCard.pet){
      contract = collectionCtx.petzMarketContract;
    }
    contract.methods.cancelOffer(activeGameCard.id).send({ from: web3Ctx.account }).on('transactionHash',()=>{
      setStatus(1);
    })
    .on('receipt', (hash) => {
      setActiveGameCard(null);
      setSelectedGameTab(0);
      setHelperState(1);
      setStatus(2)
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      setStatus(3);
    });   
  };



  let xpStates =[false,false,false];
  xpStates[0]=activeGameCard&&activeGameCard.game && xpPrices[0]<=activeGameCard.game.xp;
  xpStates[1]=activeGameCard&&activeGameCard.game && xpPrices[1]<=activeGameCard.game.xp;
  xpStates[2]=activeGameCard&&activeGameCard.game && xpPrices[2]<=activeGameCard.game.xp;
  return(

    <div>
    
    {
    <React.Fragment>
    <Modal className="profile-modal" overlayClassName="Overlay-profile" isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Rules">
    <img className="rules-close" src="assets/x2.png" onClick={closeModal}/>
        <div className="generator-wrapper col">
          <div className="col profile-inputs2">
             { activeGameCard && activeGameCard.pet && 
              <React.Fragment>
            <label className="profile-inputs-label">NAME</label>
            <div style={{paddingRight:"40px"}} className="profile-row">
                <input onFocus={(e) =>{if(activeGameCard&&activeGameCard.offer&&activeGameCard.offer.id==activeGameCard.id)setHelperState2(1);else setHelperState2(2);}} placeholder="Enter name" val={name} onChange={e=>{setName(e.target.value);}} className="lottery-input"/>
                <button onClick={updateName} className="item-btn petz-buy btn-green">Set</button>
            </div>
            </React.Fragment>
            }
            <div onMouseEnter={(e) =>{if(activeGameCard&&!activeGameCard.game)setHelperState2(6);else setHelperState2(7);}} style={{marginTop:`${(activeGameCard && activeGameCard.pet)?"20px":"90px"}`}} className="profile-left">
              <img src="assets/profileLeft.png" className="generator-inputs-img"/>
              <label className="profile-inputs-label">Statistic</label>
              <div className="profile-stat">
                Total rounds: {(activeGameCard && activeGameCard.game && (activeGameCard.game.win+activeGameCard.game.loss))||"0"}<br/>
                Win: {(activeGameCard && activeGameCard.game && activeGameCard.game.win)||"0"}<br/>
                Lost: {(activeGameCard && activeGameCard.game && activeGameCard.game.loss)||"0"}<br/>
                Experience: {(activeGameCard && activeGameCard.game && activeGameCard.game.xp)||"0"} XP<br/>
              </div>
              <div className="profile-row2">
                <label className="profile-inputs-label">+{xpValues[0]}&nbsp;Likes</label>
                <div className="profile-info">{xpPrices[0]} XP</div>
                <button style={{marginRight: "0px",lineHeight:"32px"}} onClick={(e) => {e.preventDefault();useXP(0); }} disabled={!xpStates[0]} className={`item-btn petz-buy ${xpStates[0]?"btn-green":"btn-secondary"}`}>&nbsp;Upgrade&nbsp;</button>
              </div>
              <div className="profile-row2">
                <label className="profile-inputs-label">+{xpValues[1]}&nbsp;Disikes</label>
                <div className="profile-info">{xpPrices[1]} XP</div>
                <button style={{marginRight: "0px",lineHeight:"32px"}} onClick={(e) => {e.preventDefault();useXP(1); }} disabled={!xpStates[1]} className={`item-btn petz-buy ${xpStates[1]?"btn-green":"btn-secondary"}`}>&nbsp;Upgrade&nbsp;</button>
              </div>
              <div className="profile-row2">
                <label className="profile-inputs-label">-{xpValues[2]}&nbsp;Stress</label>
                <div className="profile-info">{xpPrices[2]} XP</div>
                <button style={{marginRight: "0px",lineHeight:"32px"}} onClick={(e) => {e.preventDefault();useXP(2); }} disabled={!xpStates[2]} className={`item-btn petz-buy ${xpStates[2]?"btn-green":"btn-secondary"}`}>&nbsp;Upgrade&nbsp;</button>
              </div>
            </div>

            
          </div>
          <div style={{marginTop: "30px"}} className={`col generator-card`}>
            <img className="petz-item" src="assets/profileItem.png"/>
            {activeGameCard && activeGameCard.img && (redraw || !redraw) &&
              <div className="lottery-img">
                <CardImage fontSize={"22px"} img={activeGameCard.img} poster={activeGameCard.poster} vip={activeGameCard.vip} id={activeGameCard.id} offer={activeGameCard.offer} game={activeGameCard.game}/>
              </div>
            }
            <div style={{ width:"100%",marginTop:"-30px"}} className="profile-row">
              {activeGameCard && (!activeGameCard.game || !activeGameCard.game.inGame ) &&  (!activeGameCard.offer || activeGameCard.offer.id != activeGameCard.id ) &&
                <button style={{ width:"76%",height:"47px"}} className="item-btn petz-buy profile-save2" onClick={(e) => {e.preventDefault();addToGame(); }}>Add to Game</button>
              }
              {activeGameCard && (!activeGameCard.game || !activeGameCard.game.inGame )  && (!activeGameCard.offer || activeGameCard.offer.id == activeGameCard.id ) &&
                <button style={{ width:"76%",height:"47px"}} className="item-btn petz-buy profile-save2" onClick={(e) => {e.preventDefault();cancelHandler()}}>Cancel Sale</button>
              }
              {activeGameCard && (activeGameCard.game && activeGameCard.game.inGame) &&  (!activeGameCard.offer || activeGameCard.offer.id != activeGameCard.id ) &&
                <button style={{ width:"76%",height:"47px"}} className="item-btn petz-buy profile-save2" onClick={(e) => {e.preventDefault();window.location.href=`/#/play/`}}>Play</button>
              }
            </div>
            <div className="lottery-bubble">
              <img alt="" src={`/assets/helpers/profile/popup/${helperState2}.png`}/>
            </div>
          </div>
          <div className="col profile-inputs2 profile-inputs-right2">
            <div className="profile-left2">
              <img src="assets/profileRight.png" className="generator-inputs-img"/>
              <div className="profile-row3">
              <label className="profile-inputs-label2">More XP</label>
              <label style={{marginLeft: "auto",marginRight:"10px", fontSize:"10px"}} className="profile-inputs-label">Price 1 VVT/XP</label>
              </div>
              <div className="profile-row3">
                <input value={valueXP} onChange={e=>{setValueXP(e.target.value);}} type="number" style={{marginRight: "auto",marginLeft:"0px"}} className="profile-info2"/>
                <button onClick={buyXPHandler} style={{marginRight: "0px",width: "30%"}} className={`item-btn petz-buy btn-green`}>Buy</button>
              </div>
              <label className="profile-inputs-label2">Transfer</label>
              <div className="profile-row3">
                <input onFocus={(e) =>{if(activeGameCard&&activeGameCard.game)setHelperState2(3);else if(activeGameCard&&activeGameCard.offer.id==activeGameCard.id)setHelperState2(4);else setHelperState2(5);}} val={address} onChange={e=>{setAddress(e.target.value);}}  placeholder="Enter address" className="profile-input"/>
                <button onClick={transferHandler} style={{marginRight: "0px",width: "30%"}} className="item-btn petz-buy btn-green">Send</button>
                </div>
            <div className="profile-row3">
              <button style={{marginTop:"20px",width:"45%"}} onClick={downloadHandler} className="item-btn petz-buy btn-green profile-save">Save Image</button>
              {activeGameCard && ((activeGameCard.game && !activeGameCard.game.inGame) || !activeGameCard.game) && !activeGameCard.pet &&<button onMouseEnter={(e) =>{setHelperState2(10)}} style={{marginTop:"20px",width:"45%"}} onClick={bridgeHandler} className="item-btn petz-buy btn-green profile-save">Bridge</button>}
            </div>
            </div>
          </div>
      </div>
   </Modal>
    <div id="section">
        <img className="desktop profileIcon1" src="assets/profileBack1.png"/>
        <img className="desktop profileIcon2" src="assets/profileBack2.png"/>
        <div className="mobile generator-container container">
        <h4>Please use desktop version. Mobile game coming Q4 2022</h4>
        </div>
      <div className="desktop generator-container container">

        <div className="lottery-header">
        <img className="lottery-logo" src="assets/profileLogo.gif"/>   
        <div className="lottery-title">   
          {web3 && web3Ctx && !web3Ctx.account &&
               "Connect your wallet"
            } 
            { (web3 && web3Ctx && web3Ctx.networkId && web3Ctx.networkId != 820 )&&
               "Switch to Callisto Network"
            }   
            { (!web3)  &&
               "You need a DApp compatible browser"
            }  
             {
             (web3 && web3Ctx && web3Ctx.account && web3Ctx.networkId && web3Ctx.networkId == '0x334') && status == 0 &&
              "Select any card"
             } 
             {(web3 && web3Ctx && web3Ctx.account && web3Ctx.networkId && web3Ctx.networkId == '0x334') && status == 1 &&
              "Transaction in progress"
             }
             {(web3 && web3Ctx && web3Ctx.account && web3Ctx.networkId && web3Ctx.networkId == '0x334') && status == 2 &&
              "Transaction success"
             }
             {(web3 && web3Ctx && web3Ctx.account && web3Ctx.networkId && web3Ctx.networkId == '0x334') && status == 3 &&
              "Transaction failed"
             }
             {(web3 && web3Ctx && web3Ctx.account &&  web3Ctx.networkId && web3Ctx.networkId == '0x334') && status == 4 &&
              "Approving NFT transfer"
             } 
           </div>
        </div>
     
        <div className="generator-wrapper col">
          <div className="col profile-inputs">
          <h4>Game Cards</h4>
          <div className="profile-tabs">
            <div className="profile-tabs-header">
              <div className={`profile-tab ${selectedGameTab==0?"profile-tab-selected":""}` } onClick={e=>{setActiveGameCard(null);setSelectedGameTab(0);setHelperState(1);}}>Wallet
                <div className="profile-tab-triangle">
                  <div className="profile-tab-triangle-inner"></div>
                </div>
              </div>  
              <div className={`profile-tab ${selectedGameTab==1?"profile-tab-selected":""}` } onClick={e=>{setActiveGameCard(null);setSelectedGameTab(1);setHelperState(2);}}>Game
                <div className="profile-tab-triangle">
                  <div className="profile-tab-triangle-inner"></div>
                </div>
              </div> 
              <div className={`profile-tab ${selectedGameTab==2?"profile-tab-selected":""}` } onClick={e=>{setActiveGameCard(null);setSelectedGameTab(2);setHelperState(3);}}>Market
                <div className="profile-tab-triangle">
                  <div className="profile-tab-triangle-inner"></div>
                </div>
              </div> 
            </div>
          <div className="profile-tab-area">
             <div className="row text-center">
            { 
              data.map((NFT, _key) => {
                if(activeGameCard == null && _key ==  0){
                  if(NFT.pet){let index = collectionCtx.petzCollection.findIndex(item => item.id === NFT.id); setActiveGameCard(collectionCtx.petzCollection[index])}
                  else{let index = collectionCtx.collection.findIndex(item => item.id === NFT.id); setActiveGameCard(collectionCtx.collection[index])}

                }
              return(
                <Card fontSize="12px" selected={(activeGameCard && NFT.id == activeGameCard.id && NFT.pet == activeGameCard.pet)?true:false} onDoubleClick={openModal} 
                onClick={nft=>{
                  if(nft.pet){let index = collectionCtx.petzCollection.findIndex(item => item.id === nft.id); setActiveGameCard(collectionCtx.petzCollection[index])}
                  else{let index = collectionCtx.collection.findIndex(item => item.id === nft.id); setActiveGameCard(collectionCtx.collection[index])}
                }} hideButtons={true} vip={!NFT.pet} key={NFT.pet?(NFT.id+1000):NFT.id} game={NFT.game} id={NFT.id} offer={NFT.offer} img={NFT.img} owner={NFT.owner} web3Ctx={web3Ctx} buyHandler={()=>{}} makeOfferHandler={()=>{}} 
                cancelHandler={()=>{}} />
              )})
            }{ (selectedGameTab == 0 || selectedGameTab==2) &&
            <div className="item-wrapper">
               <div className="item">
                 <video muted={true} autoPlay={true} loop={true} className="item-images main-img" src="" poster="/assets/cardBuy.png" onClick={(e) => {e.preventDefault();window.open("/#/market", '_blank').focus();}} ></video>                         
                </div>
            </div>} 
            </div>
          </div>
            <div className="profile-tabs-footer">
              <div className={`profile-button ${(activeGameCard && (!activeGameCard.offer || activeGameCard.offer.id != activeGameCard.id) && (!activeGameCard.game || !activeGameCard.game.inGame)) ?"profile-button-active":""}`} onClick={addToGame}>Add To Game</div>   
              <div style={{paddingTop:"4px"}} className={`profile-button ${(activeGameCard && (!activeGameCard.offer || activeGameCard.offer.id != activeGameCard.id) && activeGameCard.game && activeGameCard.game.inGame) ?"profile-button-active":""}`} onClick={removeFromGame} >Remove From Game</div> 
              <div className={`profile-button ${(activeGameCard && activeGameCard.offer != null && activeGameCard.offer.id == activeGameCard.id  && !activeGameCard.game.inGame) ?"profile-button-active":""}`} onClick={cancelHandler}>Cancel Sale</div>  
            </div>
          </div>
          </div>
          <div className={`col generator-card`}>
            <img className="petz-item" src="assets/petzItem.png"/>
            {activeGameCard && activeGameCard.img &&
              <div className="lottery-img">
                <CardImage fontSize={"24px"} img={activeGameCard.img} poster={activeGameCard.poster} vip={activeGameCard.vip} id={activeGameCard.id} offer={activeGameCard.offer} game={activeGameCard.game}/>
              </div>
            }
            <div className="lottery-bubble">
              <img alt="" src={`/assets/helpers/profile/${helperState}.png`}/>
            </div>
          </div>
          <div className="col profile-inputs profile-inputs-right">
          <h4>Inventory</h4>
          <div className="profile-tabs">
            <div className="profile-tabs-header">
            </div>
          <div className="inventory-tab-area">
            <div className="profile-soon" style={{textAlign:"left",fontSize:"25px",fontFamily:"'Open Sans'"}}>Coming Soon</div>
          </div>
            <div className="profile-tabs-footer">
            </div>
          </div>
          </div>
      </div>
        </div>
    </div>

    </React.Fragment>
    }
    </div>
  );
};

export default Profile;

