import React from 'react';

const Web3Context = React.createContext({
  account: null,
  networkId: 0,
  loadAccount: () => {},
  loadNetworkId: () => {},
  switchToCallisto: () =>{},
  switchToBSC: () =>{},
  addCallisto: () => {}
});

export default Web3Context;