import { useReducer } from 'react';
import React  from 'react';
import Web3Context from './web3-context';

const defaultWeb3State = {
  account: null,
  networkId: 0
};

const web3Reducer = (state, action) => {
  if(action.type === 'ACCOUNT') {
    return {
      account: action.account,
      networkId: state.networkId
    };
  } 
  
  if(action.type === 'NETWORKID') {
    return {
      account: state.account,
      networkId: action.networkId
    };
  }
  
  return defaultWeb3State;
};

const Web3Provider = props => {
  const [web3State, dispatchWeb3Action] = useReducer(web3Reducer, defaultWeb3State);
  
  const loadAccountHandler = async(web3) => {
    if(!web3 || !web3.eth){
      return null;
    }
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    dispatchWeb3Action({type: 'ACCOUNT', account: account});
    return account;
  };
  const addCallisto = async(web3) =>{
    await window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainId: '0x334',chainName: 'Callisto Network',nativeCurrency: {name: 'Callisto Coin',symbol: 'CLO', decimals: 18},
      rpcUrls: ['https://rpc.callisto.network/'],
      blockExplorerUrls: ['https://explorer.callisto.network/']}]}
    )
    .catch((error) => {
      console.log(error)
    }) 
  }
  const switchToBSC = async(web3) =>{
    await window.ethereum.request({method: 'wallet_switchEthereumChain',  params: [{ chainId: '0x38' }]}
    )
    .catch((error) => {
      console.log(error)
    }) 
  }


  const switchToCallisto = async(web3) =>{
    await window.ethereum.request({method: 'wallet_switchEthereumChain',  params: [{ chainId: '0x334' }]}
    )
    .catch((error) => {
      console.log(error)
    }) 
  }

  const loadNetworkIdHandler = async(web3) => {
    if(!web3 || !web3.eth){
      return null;
    }
    const networkId = await web3.eth.getChainId();
    dispatchWeb3Action({type: 'NETWORKID', networkId: parseInt(networkId)});
    return networkId;   
  };
  
  const web3Context = {
    account:web3State.account,
    networkId: web3State.networkId,
    loadAccount: loadAccountHandler,
    loadNetworkId: loadNetworkIdHandler,
    switchToCallisto:switchToCallisto,
    switchToBSC:switchToBSC,
    addCallisto:addCallisto
  };
  
  return (
    <Web3Context.Provider value={web3Context}>
      {props.children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;