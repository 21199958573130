import React, { Component,useContext, useRef, createRef,useState } from 'react';

import web3 from '../../connection/web3';
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import Pagination from '../Layout/Pagination';
import Card from '../Layout/Card';

class NFTCollection extends Component {
  static contextType = CollectionContext;
  constructor(props){
    super(props);
    this.state = {currentPage: 1 };
    this.buyHandler = this.buyHandler.bind(this);
    this.makeOfferHandler = this.makeOfferHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
  }
   async connectWallet() {
     if(!this.props.web3Ctx){
        return;
      }
    try {
  await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!this.props.web3Ctx.loadAccount){
        return;
      }
    this.props.web3Ctx.loadAccount(web3);
  };
  makeOfferHandler (id,price) {
    if(parseInt(price) == 0){
      return;
    }
    const enteredPrice = web3.utils.toWei(price, 'ether');
    const makeOffer = async(enteredPrice,id)=>{
      await this.context.nftContract.methods.approve(this.context.marketContract.options.address, id).send({ from: this.props.web3Ctx.account });
      this.context.marketContract.methods.makeOffer(id, enteredPrice,false).send({ from: this.props.web3Ctx.account })
        .on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');

        }); 
      }
    const makePetOffer = async(enteredPrice,id)=>{
      await this.context.petzContract.methods.approve(this.context.petzMarketContract.options.address, id).send({ from: this.props.web3Ctx.account });
      this.context.petzMarketContract.methods.makeOffer(id, enteredPrice,false).send({ from: this.props.web3Ctx.account })
        .on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
        }); 
      }
    if(this.props.marketType){
      makePetOffer(enteredPrice,id)
    }else{
      makeOffer(enteredPrice,id);
    }
  };

  buyHandler (id) {    
    if(!this.props.web3Ctx.account){
      this.connectWallet();
      return;
    }
    if(this.props.marketType){
    const  buyIndex = this.context.petzCollection.findIndex(item => item.id === id);    
    this.context.petzMarketContract.methods.fillOffer(this.context.petzCollection[buyIndex].id).send({ from: this.props.web3Ctx.account, value: this.context.petzCollection[buyIndex].offer.price })
    .on('transactionHash', (hash) => {
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
    });   
    }else{
    const  buyIndex = this.context.collection.findIndex(item => item.id === id);    
    this.context.marketContract.methods.fillOffer(this.context.collection[buyIndex].id).send({ from: this.props.web3Ctx.account, value: this.context.collection[buyIndex].offer.price })
    .on('transactionHash', (hash) => {
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
    });            
    }
  };

  cancelHandler(id) {  
    let contract = this.context.marketContract;
    if(this.props.marketType){
      contract = this.context.petzMarketContract;
    }
    contract.methods.cancelOffer(id).send({ from: this.props.web3Ctx.account })
    .on('transactionHash', (hash) => {
    })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
    });   
  };
  onPageChanged = data => {
    const { currentPage, totalPages, pageLimit } = data;
      this.setState({currentPage:currentPage});
  }
 // console.log(data);
  render() {


  let data=this.context.collection;
  if(this.props.marketType){
    data = this.context.petzCollection;
  }
  console.log(data);
  data= data.filter(NFT=>{
        const onSale = (NFT.offer != null && NFT.offer.id == NFT.id) ? true : false;
        const owner = !onSale ? NFT.owner : NFT.offer.user;
        if(this.props.filterMy)
        {
          return this.props.web3Ctx.account == owner;
        }
        if(this.props.filterSale)
        {
          return onSale;
        }
        return true;
      }).sort((a, b) => a.id < b.id? 1 : -1)
  let total =  data.length;
  data= data.slice((this.state.currentPage-1)*50,(this.state.currentPage)*50);
//console.log(data);
  return(
    <React.Fragment>
    <div className="row text-center item-row">
      { 
      data.map((NFT, _key) => {
        return(
              <Card fontSize="22px" vip={!this.props.marketType} key={NFT.id} id={NFT.id} game={NFT.game} offer={NFT.offer} img={NFT.img} owner={NFT.owner} web3Ctx={this.props.web3Ctx} buyHandler={this.buyHandler} makeOfferHandler={(id, price) => this.makeOfferHandler(id,price)} 
              cancelHandler={this.cancelHandler} />
      )})
      } 
      </div>
      <div style={{display:"flex"}} className="flex-row py-4 align-items-center">
        <Pagination totalRecords={total} pageLimit={50} pageNeighbours={1}  onPageChanged={this.onPageChanged}/>
      </div>  
  </React.Fragment>
  )}
};

export default NFTCollection;