import React, { Component, Fragment } from 'react';
import { formatPrice } from '../../helpers/utils';
import CardImage from "./CardImage"
import Web3Context from '../../store/web3-context';

class Card extends Component{
  static  contextType = Web3Context;
  constructor(props) {
    super(props);
    this.state = {priceValue:''};
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState){
   if(this.state.priceValue!=nextState.priceValue){
     return true;
   }
   if(this.props && nextProps && this.props.selected!=nextProps.selected){
     return true;
   }
   try{
     if(this.props.game.name!=nextProps.game.name){
       return true;
     }
   }catch(e){}
  if(this.props.id === nextProps.id && this.props.owner === nextProps.owner && this.props.img === nextProps.img ){
    if(this.props.offer === null && nextProps.offer === null && this.props.game === null && nextProps.game === null){
      return false;
    }
    if(this.props.offer !== null && nextProps.offer !== null  && nextProps.game == null ){
      if(this.props.offer.id === nextProps.offer.id && this.props.offer.price === nextProps.offer.price && this.props.offer.user === nextProps.offer.user){
        return false;
      }
    }

  }
  return true;
}
handleChange(event) {
    this.setState({priceValue: event.target.value});
  }
  render() {

  
  const onSale = (this.props.offer !== null && this.props.offer.id === this.props.id) ? true : false;
  const owner = !onSale ? this.props.owner : this.props.offer.user;

    const price = onSale ? formatPrice(this.props.offer.price) : null;
    const buyClass = owner === "0x25a861038FBBD6fB891491F9A6806d604d9aA76e" ?  (this.props.id == 94? 'btn-gold': 'btn-gray') : 'btn-green';
const currency = this.context.networkId === 820 ? "CLO" : (this.context.networkId === 119 ? "NULS" :"");

    return (
      <Fragment>
           <div className={`item-wrapper`}>
                      <CardImage selected={this.props.selected} fontSize={this.props.fontSize} img={this.props.img} vip={this.props.vip} id={this.props.id} offer={this.props.offer} game={this.props.game} onClick={this.props.onClick} onDoubleClick={this.props.onDoubleClick}/>
                      {  this.props.hideButtons!=true && onSale && owner !== this.props.web3Ctx.account &&                      
                        <button onClick={(e) => {e.preventDefault();this.props.buyHandler(this.props.id)}}  value={this.props.id} style={{width:'80%' }} className={"item-btn group " + buyClass}>{"BUY"} for {price}&nbsp;{currency}</button>
                      }
                      { this.props.hideButtons!=true && !onSale && owner == this.props.web3Ctx.account && !this.props.game.inGame &&                    
                       <form className="btn-group-box" >  
                          <input placeholder={`INPUT ${currency}`} value={this.state.priceValue} onChange={this.handleChange} type="text" className="item-input group"/>
                          <button onClick={(e) => {e.preventDefault();this.props.makeOfferHandler(this.props.id, this.state.priceValue)} } className="item-btn group">Sell</button>
                          </form>
                        
                      }
                      { this.props.hideButtons!=true && onSale && owner == this.props.web3Ctx.account &&                      
                        <button onClick={(e) => {e.preventDefault();this.props.cancelHandler(this.props.id)} } style={{width:'80%' }}  className="item-btn group btn-pink">Cancel: {price}&nbsp;{currency}</button>
                      }


            </div>
      </Fragment>
    );

  }
}

export default Card;