import { useReducer } from 'react';
import CollectionContext from './collection-context';
import NFTCreature from '../abis/NFTCreature.json';
import BlogPetz from '../abis/BlogPetz.json';
import ICO from '../abis/ICO.json';
import Game from '../abis/VIPGame.json';
import NFTMarketplace from '../abis/NFTMarketplace.json';
import NFTBridge from '../abis/NFTBridge.json';
import VVTFarm from '../abis/VVTFarm.json';
import React  from 'react';

let marketContractAddress = [];
marketContractAddress[820] = "0x755168d5baad6B20d2f6f96227ACf84001Bb2A24";
marketContractAddress[119] = "0xC98F531A7Cf4E44d51FE73ec277457d29A272d59";

let nftContractAddress = []

nftContractAddress[820] = "0xB0900E797044dE83b7471E39D7C7a2026E4AF5E7";
nftContractAddress[119] = "0xb1906BdC678A08309D5c50f4De328fD3ac940702";
nftContractAddress[56] = "0xac9D6Bb8DB04373f6fe9B0Cef032C12471831005";

let petzContractAddress = []
petzContractAddress[820] = "0x4B0d959e551E55aF9c89CE96C0c7755c189aD622";
petzContractAddress[119] = "0x54f2DdD9800874a6A5Cc657e46fD1aeCB3509Bf8";

let petzMarketContractAddress = [];
petzMarketContractAddress[820] = "0xf2fD39aeEE99CcE542AeB9345a60D55C2b53F84A";
petzMarketContractAddress[119] = "0x507eDa35Ad9E4F1C8D8F4B70e8e44080BA42970D";


let gameContractAddress = [];
gameContractAddress[820] = "0x9e76E14B53AC12057CfD943Ca17035aB628ed010";
gameContractAddress[119] = "0x0D28aBDc7290a4194A6317276c58F72E4ae96C0D";

//const icoContractAddress = "0x00f1A5bED800ae746b7360E875A5a8B0d84980DE";

let bridgeContractAddress =[];
bridgeContractAddress[820] = "0xcfF22BCd0eEB1b65E9888eB4d3266fa3d5304192";
bridgeContractAddress[56] = "0xcfF22BCd0eEB1b65E9888eB4d3266fa3d5304192";
bridgeContractAddress[119] = "0x842A019c718e5c3758EdF053A219272624bB890C";

const farmContractAddress = "0x2F48B8887d2D5d5B718C9f6516B44BA1C1BB8db1";

const defaultCollectionState = {
  farmContract:null,
  nftContract: null,
  marketContract : null,
  petzMarketContract : null,
  petzContract : null,
  gameContract : null,
  bridgeContract : null,
  totalSupply: null,
  petzTotalSupply:null,
  collection: [],
  petzCollection: [],
  userFunds : null,
  petFunds : null,
  gameFunds: null,
  arenas : []
};

const collectionReducer = (state, action) => {
      let _state = Object.assign({}, state);
  if(action.type === 'CONTRACT') { 
    _state.gameContract = action.gameContract;
    _state.farmContract = action.farmContract;
    _state.nftContract = action.nftContract;
    _state.petzContract = action.petzContract;
    _state.marketContract = action.marketContract;
    _state.petzMarketContract = action.petzMarketContract;
    _state.bridgeContract = action.bridgeContract;
    return _state;
  } 
  if(action.type === 'LOADFUNDS') {
    _state.userFunds = action.userFunds;
    _state.petFunds = action.petFunds;
    _state.gameFunds = action.gameFunds;
    return _state;
  }
  if(action.type === 'LOADSUPPLY') {
    _state.totalSupply = parseInt(action.totalSupply);
    return _state;
  }
 if(action.type === 'LOADPETZSUPPLY') {
    _state.petzTotalSupply = parseInt(action.petzTotalSupply);
    return _state;
  }

  if(action.type === 'LOADCOLLECTION') {
    _state.collection = action.collection;
    return _state;
  }

  if(action.type === 'LOADPETZCOLLECTION') {
    _state.petzCollection = action.petzCollection;
    return _state;    
  }

  if(action.type === 'UPDATECOLLECTION') {    
    let index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.NFT.id));
    if(index === -1) {
      _state.collection = [action.NFT, ..._state.collection];
    } else {
      _state.collection[index].offer = action.NFT.offer;
      _state.collection[index].owner = action.NFT.owner;
      _state.collection[index].game = action.NFT.game;
    }    
    return _state;   
  }

  if(action.type === 'UPDATEPETZCOLLECTION') {    
    let index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.NFT.id));
    if(index === -1) {
      _state.petzCollection = [action.NFT, ..._state.petzCollection];
    } else {
      _state.petzCollection[index].offer = action.NFT.offer;
      _state.petzCollection[index].owner = action.NFT.owner;
      _state.petzCollection[index].game = action.NFT.game;
    }    
    return _state; 
  }

  if(action.type === 'REMOVEARENA') {    
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.id));
    if(index === -1) {
      return _state
    } else {
      _state.arenas.splice(index,1);
    }    
    return _state; 
  }

  if(action.type === 'UPDATEARENA') {    
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.arena.id));
    if(index === -1) {
      _state.arenas = [action.arena, ..._state.arenas];
    } else {
      _state.arenas[index] = action.arena;
    }    
    return _state; 
  }
    if(action.type === 'UPDATEROUND') {    
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.id));
    if(index === -1) {
      _state.arenas = [{id:action.id,currentRound:action.round}, ..._state.arenas];
    } else {
      _state.arenas[index].currentRound = action.round;
    }    
    return _state; 
  }
  if(action.type === 'GAMESTART') {    
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.id));
    if(index === -1) {
      _state.arenas = [{id:action.id,currentRound:0}, ..._state.arenas];
    } else {
      _state.arenas[index].status = 1;
    }    
    //console.log(_state.arenas);
    return _state; 
  }
    if(action.type === 'GAMEEND') {    
    _state.petzCollection.every((elem, index, arr) => {if(elem.game && elem.game.gameId == action.id){arr[index].game.gameId = 0; }return true;})
    _state.collection.every((elem, index, arr) => {if(elem.game && elem.game.gameId == action.id){arr[index].game.gameId = 0; }return true;})
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.id));
    if(index!=-1)
      {
       // _state.arenas[index].users = 0;
        _state.arenas[index].status = 2;
      }  
    return _state; 
  }
  if(action.type === 'REMOVEFROMGAME') {    
    _state.petzCollection.every((elem, index, arr) => {if(elem.game && elem.game.gameId == action.id){arr[index].game.gameId = 0; }return true;})
    _state.collection.every((elem, index, arr) => {if(elem.game && elem.game.gameId == action.id){arr[index].game.gameId = 0; }return true;})
     let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.id));
    if(index!=-1)
      {
       // _state.arenas[index].users = 0;
        _state.arenas[index].status = 2;
    }
    return _state; 
  }
  if(action.type === 'ADDTOGAME') {    
    _state.petzCollection.every((elem, index, arr) => {if(elem.game && elem.game.cardId == action.id){arr[index].game.gameId = action.gameId;}return true;})
    _state.collection.every((elem, index, arr) => {if(elem.game && elem.game.cardId == action.id){arr[index].game.gameId = action.gameId; }return true;})
    let index = _state.arenas.findIndex(arena => arena.id === parseInt(action.gameId));
    if(index!=-1){
      //_state.arenas[index].users=action.users;
      //_state.arenas[index].status=action.status;
    }
    return _state; 
  }
  if(action.type === 'UPDATEOWNER') {
    let index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.collection = [{id:action.id,offer:null,owner:'',pet:false}, ..._state.collection];
      index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.collection[index].owner = action.newOwner;
    return _state; 
  }

    if(action.type === 'UPDATEPETZOWNER') {
    let index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.petzCollection = [{id:action.id,offer:null,owner:'',pet:true,game:{}}, ..._state.petzCollection];
      index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.petzCollection[index].owner = action.newOwner;

    return _state; 
  }
    if(action.type === 'UPDATECARD') {
    let index = _state.petzCollection.findIndex(NFT => NFT.game && NFT.game.cardId === parseInt(action.cardId));
    if(index != -1) {
      _state.petzCollection[index].game.likes =action.likes;
      _state.petzCollection[index].game.dislikes =action.dislikes;
      _state.petzCollection[index].game.stress =action.stress;
      _state.petzCollection[index].game.xp =action.xp;     
    } 

    return _state; 
  }
  if(action.type === 'UPDATEOFFER') {
    let index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.collection = [{id:action.id,owner:'',pet:false,game:null}, ..._state.collection];
      index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.collection[index].offer = action.offer;
    return _state; 
  }

  if(action.type === 'UPDATEPETZOFFER') {
    let index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.petzCollection = [{id:action.id,owner:'',pet:true,game:{}}, ..._state.petzCollection];
      index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.petzCollection[index].offer = action.offer;
    return _state; 
  }
  if(action.type === 'UPDATEGAME') {
    let index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.collection = [{id:action.id,owner:'',pet:false,offer:null}, ..._state.collection];
      index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.collection[index].game.inGame = action.inGame;
    return _state; 
  }
    if(action.type === 'UPDATEPETZGAME') {
    let index = state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      state.petzCollection = [{id:action.id,owner:'',pet:true,offer:null,img:"",game:{}}, ...state.petzCollection];
      index = state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    state.petzCollection[index].game.inGame = action.inGame;

    return _state; 
  }
  if(action.type === 'UPDATEIMAGE') {
    let index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.collection = [{id:action.id,offer:null,owner:'',pet:false}, ..._state.collection];
      index = _state.collection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.collection[index].img = action.newImage;
    return _state; 
  }
  if(action.type === 'UPDATEPETZIMAGE') {
    let index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    if(index === -1) {
      _state.petzCollection = [{id:action.id,offer:null,owner:'',pet:true,game:{}}, ..._state.petzCollection];
      index = _state.petzCollection.findIndex(NFT => NFT.id === parseInt(action.id));
    } 
    _state.petzCollection[index].img = action.newImage;

    return _state; 
  }
  
  return defaultCollectionState;
};

const CollectionProvider = props => {
  const [CollectionState, dispatchCollectionAction] = useReducer(collectionReducer, defaultCollectionState);
  let marketContract = null;
  let petzMarketContract = null;
  let nftContract = null;
  let petzContract = null;
  let gameContract = null;
  let bridgeContract = null;
  let farmContract = null;
  let web3 = null;
  async function loadPetzContractHandler(_web3){
    if(!_web3 || !_web3.eth){
      return null;
    }
    let networkID = await _web3.eth.getChainId();
    return new _web3.eth.Contract(BlogPetz.abi, petzContractAddress[networkID]);
  }
  async function loadContractHandler(_web3,account){
    if(!_web3 || !_web3.eth){
      return null;
    }

    web3 = _web3;
    let networkID = await web3.eth.getChainId();
    //console.log("net",networkID)
    nftContract = new web3.eth.Contract(NFTCreature.abi, nftContractAddress[networkID]);
    marketContract = new web3.eth.Contract(NFTMarketplace.abi, marketContractAddress[networkID]);
    petzMarketContract = new web3.eth.Contract(NFTMarketplace.abi, petzMarketContractAddress[networkID]);
    petzContract = new web3.eth.Contract(BlogPetz.abi, petzContractAddress[networkID]);
    gameContract = new web3.eth.Contract(Game.abi, gameContractAddress[networkID]);
    //icoContract = new web3.eth.Contract(ICO.abi, icoContractAddress);
    bridgeContract = new web3.eth.Contract(NFTBridge.abi, bridgeContractAddress[networkID]);

    farmContract = new web3.eth.Contract(VVTFarm.abi, farmContractAddress);



    dispatchCollectionAction({type: 'CONTRACT', farmContract:farmContract, nftContract: nftContract, marketContract : marketContract,petzContract:petzContract,petzMarketContract:petzMarketContract,gameContract:gameContract,bridgeContract:bridgeContract}); 
    if(!nftContract){
      return;
    }
    if(networkID == 56 ){
      //console.log("loading BSC:");
      const totalSupply = await nftContract.methods.totalSupply().call();
      dispatchCollectionAction({type: 'LOADSUPPLY', totalSupply: totalSupply});


    let collection = [];
    for(let i = 1; i <= totalSupply; i++) {
      let lastId = i;
      nftContract.methods.tokenURI(i).call().then(hash=>
        {
          fetch(hash, {mode: 'cors'}).then(response => response.json()).then(data=>{
            //console.log(data);
            updateImageHandler(lastId,data.image)
          })

        });
      try {
        nftContract.methods.ownerOf(i).call().then(owner =>{
          updateOwnerHandler(lastId,owner);
        });
        collection = [{
          id: i,
          img: '',
          pet:false,
          owner: '',
          offer: null,
          game:null
        }, ...collection];
      }catch(e) {
        console.error('Something went wrong',e);
      }
    }
    dispatchCollectionAction({type: 'LOADCOLLECTION', collection: collection});  
    }else if(networkID ==820 || networkID == 119){
    //let res  = await gameContract.methods.calcShares("0x190E122B1a18BA4f8F7e20c1355a97586E5e7153").call({ from: "0x25a861038FBBD6fB891491F9A6806d604d9aA76e" });
    //console.log("calc pending",res);
    let collection = [];
    let petzCollection = [];
    gameContract.methods.cardInfo().call().then(data=>{
      console.log("cardinfo:",data);
      for(let i = 0; i < data.length; i++) {
        let card = data[i];
        let game={};
        game.id = parseInt(card.id);
        game.cardId = parseInt(card.id);
        game.gameId = parseInt(card.stat[0]);
        game.owner = card.owner;
        game.name = card.name;
        game.petType = card.stat[9];
        game.inGame = card.stat[11] == "1";
        game.legend = card.stat[10] == "1";
        //console.log("info",card.stat[10]);
        game.xp = parseInt(card.stat[1]); 
        game.win = parseInt(card.stat[2]);
        game.loss = parseInt(card.stat[3]);
        game.rating = parseInt(card.stat[4]);
        game.likes = parseInt(card.stat[5]);
        game.dislikes = parseInt(card.stat[6]);
        game.stress = parseInt(card.stat[7]);
        let pet = true;
        if(game.id>100000){
          game.id-=100000;
          pet = false;
        }
        game.id-=1;
        if(pet){
          petzCollection = [{
            id: game.id,
            img: card.tokenURI,
            owner: game.owner,
            pet: true,
            offer: null,
            game:game
          }, ...petzCollection];
          let lastId = game.id;
          petzMarketContract.methods.offers(lastId).call().then(_offer =>{
            let offer ={};
            offer.price = _offer.priceCLO;
            offer.id = parseInt(_offer.tokenId);
            offer.user = _offer.user;
            updatePetzOfferHandler(lastId,offer);
          });
        }else{
           collection = [{
            id: game.id,
            img: card.tokenURI,
            owner: game.owner,
            pet: false,
            offer: null,
            game:game
          }, ...collection];
          let lastId = game.id;
          marketContract.methods.offers(lastId).call().then(_offer =>{
            let offer ={};
            offer.price = _offer.priceCLO;
            offer.id = parseInt(_offer.tokenId);
            offer.user = _offer.user;
            updateOfferHandler(lastId,offer);
          });
         }
      }
      //console.log("collection:",petzCollection);
      dispatchCollectionAction({type: 'LOADPETZCOLLECTION', petzCollection: petzCollection});
      dispatchCollectionAction({type: 'LOADCOLLECTION', collection: collection});
       
      })
    }

    
    if(networkID == 820 || networkID == 119){
    gameContract.methods.getActiveGames().call().then(data=>{
      for(let i = 1; i < data.games.length; i++) {
        let lastId = i;
        let config = data.configs[i];
        let arena={};
        arena.id = lastId;
        arena.name = config.name;
        arena.fee = config.fee;
        arena.owner = config.owner;
        arena.arena = parseInt(config.arena);
        arena.maxUsers = parseInt(config.maxUsers);
        let game = data.games[i];
        arena.gameType = parseInt(game.gameType);
          arena.status = parseInt(game.status);
          arena.currentRound = parseInt(game.currentRound);
          arena.users = parseInt(game.users);
          arena.roundTime = parseInt(game.roundTime);
       
        dispatchCollectionAction({type: 'UPDATEARENA', id: arena.id, arena: arena});
      }
    })
  
   gameContract.events.SpendXP().on('data', (event) => {
      let cardId = parseInt(event.returnValues.cardId);
      let likes = parseInt(event.returnValues.likes);
      let dislikes = parseInt(event.returnValues.dislikes);
      let stress = parseInt(event.returnValues.stress);
      let xp = parseInt(event.returnValues.xp);
      dispatchCollectionAction({type: 'UPDATECARD', cardId: cardId, likes:likes,dislikes:dislikes,stress:stress,xp:xp});
    });
    gameContract.events.RoundEnd().on('data', (event) => {
      let id = parseInt(event.returnValues.gameId);
      let round = parseInt(event.returnValues.round)+1;
      dispatchCollectionAction({type: 'UPDATEROUND', id: id, round: round});
    });
    gameContract.events.GameEnded().on('data', (event) => {
      let id = parseInt(event.returnValues.gameId);
      dispatchCollectionAction({type: 'REMOVEFROMGAME', id: id});
      web3.eth.getAccounts().then( (accounts)=>{
        if(accounts && accounts[0]){
          loadUserFundsHandler(accounts[0]);
        }
      });
    });
    gameContract.events.GameEnd().on('data', (event) => {
      let id = parseInt(event.returnValues.gameId);
      dispatchCollectionAction({type: 'GAMEEND', id: id});
      web3.eth.getAccounts().then( (accounts)=>{
        if(accounts && accounts[0]){
          loadUserFundsHandler(accounts[0]);
        }
      });
    });
    gameContract.events.GameStart().on('data', (event) => {
      let id = parseInt(event.returnValues.gameId);
      dispatchCollectionAction({type: 'GAMESTART', id: id});
    });
    gameContract.events.CardJoined().on('data', (event) => {
      let gameId = parseInt(event.returnValues.gameId);
      let id = parseInt(event.returnValues.id);
      dispatchCollectionAction({type: 'ADDTOGAME', id: id,gameId:gameId});
    });
    gameContract.events.GameCreated().on('data', (event) => {
      let game = event.returnValues.info;
      let config = event.returnValues.config;
      let gameId = parseInt(event.returnValues.gameId);
      let arena={};
      arena.id = gameId;
      arena.gameType = parseInt(game.gameType);
      arena.status = parseInt(game.status);
      arena.users = parseInt(game.users);
      arena.roundTime = parseInt(game.roundTime);
      arena.currentRound = parseInt(game.currentRound);
      arena.name = config.name;
      arena.fee = config.fee;
      arena.owner = config.owner;
      arena.arena = parseInt(config.arena)
      arena.maxUsers = parseInt(config.maxUsers);
      dispatchCollectionAction({type: 'UPDATEARENA', id: arena.id, arena: arena});
    });
    /*gameContract.events.GameCanceled().on('data', (event) => {
      let id = parseInt(event.returnValues.gameId);
      dispatchCollectionAction({type: 'REMOVEARENA', id: id});
    });*/
//

    if(account){
      loadUserFundsHandler(account);
    }
    }
    
    nftContract.events.Transfer().on('data', (event) => {
      if(event.returnValues.to==gameContractAddress || event.returnValues.from==gameContractAddress){
        return;
      }
      updateCollectionHandler(parseInt(event.returnValues.tokenId), event.returnValues.to);
      web3.eth.getAccounts().then( (accounts)=>{
        if(accounts && accounts[0]){
          loadUserFundsHandler(accounts[0]);
        }
      });
    });
    if(networkID != 56){
    petzContract.events.Transfer().on('data', (event) => {
      if(event.returnValues.to == gameContractAddress || event.returnValues.from==gameContractAddress){
        return;
      }
      //console.log("Transfer",event);
      if(event.returnValues.from == "0x0000000000000000000000000000000000000000"){
        loadContractHandler(web3,account);
      }else{
        updatePetzCollectionHandler(parseInt(event.returnValues.tokenId), event.returnValues.to);
      }
      let lastId = parseInt(event.returnValues.tokenId);
      web3.eth.getAccounts().then( (accounts)=>{
        if(accounts && accounts[0]){
          loadUserFundsHandler(accounts[0]);
        }
      });
    });
    petzContract.events.NewImage().on('data', (event) => {
      updatePetzImageHandler(parseInt(event.returnValues.tokenID), event.returnValues.url);
    });
    gameContract.events.AddedToContract().on('data', (event) => {
      let id = parseInt(event.returnValues.id);
      dispatchCollectionAction({type: event.returnValues.collection==petzContractAddress?'UPDATEPETZGAME':'UPDATEGAME', id: id, inGame: true});
    });

    gameContract.events.RemovedFromContract().on('data', (event) => {
      let id = parseInt(event.returnValues.id);
      dispatchCollectionAction({type: event.returnValues.collection==petzContractAddress?'UPDATEPETZGAME':'UPDATEGAME', id: id, inGame: false});
    });
  }
    return petzContract;
  };

  async function loadUserFundsHandler(account){
    let contract = (marketContract|| CollectionState.marketContract);
    if(!contract){
      return null;
    }
    let petzContract = (petzMarketContract|| CollectionState.petzMarketContract);
    if(!petzContract){
      return null;
    }
    let gameContractTmp = (gameContract|| CollectionState.gameContract);
    if(!gameContractTmp){
      return null;
    }

    const userFunds = await contract.methods.userFunds(account).call();
    const userPetzFunds = await petzContract.methods.userFunds(account).call();
    const gameFunds = await gameContractTmp.methods.userFunds(account).call();
    dispatchCollectionAction({type: 'LOADFUNDS', userFunds: userFunds,petFunds: userPetzFunds, gameFunds:gameFunds});
    //console.log("funds 2",userFunds,userPetzFunds,gameFunds);
    return parseInt(userFunds)+parseInt(userPetzFunds) + parseInt(gameFunds) ;
  };
  const updateCollectionHandler = async(id, owner) => {
    if(!marketContract){
      return;
    }
    let lastId = parseInt(id);
      marketContract.methods.offers(lastId).call().then(_offer =>{
          let offer ={};
          offer.price = _offer.priceCLO;
          offer.id = parseInt(_offer.tokenId);
          offer.user = _offer.user;
          updateOfferHandler(lastId,offer);
      });
    updateOwnerHandler(id,owner);
    nftContract.methods.tokenURI(lastId).call().then(hash=>
    {
      updateImageHandler(lastId,hash)
    });
  };

    const updatePetzCollectionHandler = async(id, owner) => {
    if(!petzMarketContract){
      return;
    }
    let lastId = parseInt(id);
      petzMarketContract.methods.offers(lastId).call().then(_offer =>{
          let offer ={};
          offer.price = _offer.priceCLO;
          offer.id = parseInt(_offer.tokenId);
          offer.user = _offer.user;
          updatePetzOfferHandler(lastId,offer);
      });
    updatePetzOwnerHandler(id,owner);
    petzContract.methods.tokenURI(lastId).call().then(hash=>
    {
      updatePetzImageHandler(lastId,hash)
    });
  };

  const updateOwnerHandler = (id, newOwner) => {
    dispatchCollectionAction({type: 'UPDATEOWNER', id: id, newOwner: newOwner});
  };
  const updateImageHandler = (id, newImage) => {
    dispatchCollectionAction({type: 'UPDATEIMAGE', id: id, newImage: newImage});
  };

   const updateOfferHandler = (id, offer) => {
    dispatchCollectionAction({type: 'UPDATEOFFER', id: id, offer: offer});
  };

  const updatePetzOwnerHandler = (id, newOwner) => {
    dispatchCollectionAction({type: 'UPDATEPETZOWNER', id: id, newOwner: newOwner});
  };
  const updatePetzImageHandler = (id, newImage) => {
    dispatchCollectionAction({type: 'UPDATEPETZIMAGE', id: id, newImage: newImage});
  };

  const updatePetzOfferHandler = (id, offer) => {
    dispatchCollectionAction({type: 'UPDATEPETZOFFER', id: id, offer: offer});
  };

  const collectionContext = {
    farmContract: CollectionState.farmContract,
    bridgeContract: CollectionState.bridgeContract,
    gameContract: CollectionState.gameContract,
    nftContract: CollectionState.nftContract,
    marketContract: CollectionState.marketContract,
    petzContract: CollectionState.petzContract,
    petzMarketContract: CollectionState.petzMarketContract,
    totalSupply: CollectionState.totalSupply,
    petzTotalSupply: CollectionState.petzTotalSupply,
    collection: CollectionState.collection,
    petzCollection: CollectionState.petzCollection,
    userFunds: CollectionState.userFunds,
    petFunds :CollectionState.petFunds,
    gameFunds:CollectionState.gameFunds,
    arenas: CollectionState.arenas,
    loadPetzContract: loadPetzContractHandler,
    loadUserFunds: loadUserFundsHandler,
    loadContract: loadContractHandler,
    updateCollection: updateCollectionHandler,
    updateOwner: updateOwnerHandler,
    updateOffer: updateOfferHandler,
    updatePetzCollection: updatePetzCollectionHandler,
    updatePetzOwner: updatePetzOwnerHandler,
    updatePetzOffer: updatePetzOfferHandler
  };
  
  return (
    <CollectionContext.Provider value={collectionContext}>
      {props.children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;